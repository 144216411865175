import { v4 as uuidv4 } from 'uuid'

import { type FirebaseApp, initializeApp } from 'firebase/app'

import {
  doc,
  getDoc,
  getFirestore,
  DocumentReference,
  initializeFirestore,
} from 'firebase/firestore'

import {
  type StorageReference,
  ref,
  getStorage,
  deleteObject,
  uploadString,
  getDownloadURL,
  getMetadata,
  getBlob,
} from 'firebase/storage'

import {
  initializeAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth'

import { Collections } from '@constants/api'

import { Logger } from './log'

import { ErrorCodes, ErrorService } from './error'

//Apps
const ConfigCredentials = {
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  },
}

const buildFirestore = (app: FirebaseApp) => {
  try {
    return initializeFirestore(app, {
      ignoreUndefinedProperties: true,
      experimentalForceLongPolling: false,
    })
  } catch (error) {
    return getFirestore(app)
  }
}

export const app = initializeApp(ConfigCredentials.firebaseConfig)

export const storage = getStorage(app)

export const firestore = buildFirestore(app)

export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
})

// Utils
export const parseEntityToRef = <T extends { uid: string }>(entity: T) => {
  const userRef = doc(firestore, Collections.USERS.NAME, entity.uid)

  return userRef as DocumentReference<T>
}

export const parseRefToEntity = async <T>(ref: DocumentReference) => {
  try {
    const entitySnap = await getDoc(ref)

    return entitySnap.data() as T
  } catch (error) {
    console.error('Error getting entity', error)
    throw error
  }
}

export const parseFieldsBase64 = (base64: string) => {
  const fields = base64.split(';')

  const contentType = fields[0].split(':')[1]

  const data = fields[1].split(',')[1]

  return { contentType, data }
}

export const getURLAttachment = async (storageRef: StorageReference) => {
  try {
    return await getDownloadURL(storageRef)
  } catch (error) {
    Logger.error('Error getting attachment', error)
  }
}

type UploadAttachmentParams = {
  entity: 'rawMaterial' | 'commit' | 'geoCheckpoint' | 'tool' | 'commitTool'
  entityId: string
  base64: string
}

export const uploadAttachment = async (params: UploadAttachmentParams) => {
  try {
    const { entity, entityId, base64 } = params

    const { contentType, data } = parseFieldsBase64(base64)

    const attachmentPath = `${entity}/${entityId}/${uuidv4()}.${contentType.replace('image/', '')}`

    const fileRef = ref(storage, attachmentPath)

    await uploadString(fileRef, data, 'base64', {
      contentType,
    })

    const url = await getURLAttachment(fileRef)

    if (!url) {
      throw ErrorService.get(ErrorCodes.ERROR_UPLOADING_ATTACHMENT)
    }

    return url
  } catch (error) {
    Logger.error('Error uploading attachment', error)
    throw ErrorService.get(ErrorCodes.ERROR_UPLOADING_ATTACHMENT)
  }
}

export const getStockId = (entityID: string, itemId: string) => {
  return `${entityID}-${itemId}`
}

type DeleteAttachmentParams = {
  attachmentsPath: string[]
}

export const deleteAttachments = async (params: DeleteAttachmentParams) => {
  try {
    const { attachmentsPath } = params
    for (const attachment of attachmentsPath) {
      const fileRef = ref(storage, attachment)

      await deleteObject(fileRef)
    }
  } catch (error) {
    Logger.error('Error deleting attachments', error)
    throw ErrorService.get(ErrorCodes.ERROR_DELETING_ATTACHMENTS)
  }
}

export const downloadAttachment = async (url: string, filename: string) => {
  const fileRef = ref(storage, url)

  try {
    const metadata = await getMetadata(fileRef)

    const blob = await getBlob(fileRef)

    const contentType = metadata.contentType || 'image/*'

    const element = document.createElement('a')

    element.href = URL.createObjectURL(blob)
    element.download = `${filename}.${contentType.split('/')[1]}`
    element.click()
  } catch (error) {
    Logger.error('Error downloading attachment', error)
    throw ErrorService.get(ErrorCodes.ERROR_DOWNLOADING_ATTACHMENT)
  }
}
