/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { IconSearch, IconTrash, IconX } from '@tabler/icons-react'
import { UseListStateHandlers } from '@mantine/hooks'
import {
  Text,
  Paper,
  Table,
  Button,
  Checkbox,
  Pagination,
  SelectProps,
  NumberInputProps,
  CheckboxProps,
  Menu,
} from '@mantine/core'

import { UI } from '@constants/app'

import { usePagination } from '@hooks/pagination'

import Input from './input'
import LoaderText from './loader'
import { LIMIT_ITEMS } from '@constants/api'
import { useForm } from '@mantine/form'
import { $ } from '@utils/styles'
import { useIsMobile } from '@hooks/mobile'
import moment from 'moment'

type KeyParametrized<T> = {
  key: keyof T
}

type KeyGenericType = {
  key: string
}

type TransversalColumn<T> = (KeyParametrized<T> | KeyGenericType) & {
  label: string
  width?: string
  align?: 'left' | 'center' | 'right'
  searchable?: boolean
  hideMenu?: boolean
}

type ColumnTypeText = {
  type: 'text'
}

type SelectPropsType = Omit<SelectProps, 'onChange'> & { onChange?: OnChangeType }

type ColumnTypeSelect = {
  type: 'select'
  inputProps?: SelectPropsType
}

type OnChangeType<T = string> = (value: T, uid: string) => void

type DefaultValueType<T, K = string | number> = (item: T) => K

type GetPrefixType<T> = (item: T) => string

type GetSuffixType<T> = (item: T) => string

type NumberInputPropsType<T, K = string | number> = Omit<
  NumberInputProps,
  'onChange' | 'defaultValue' | 'prefix' | 'suffix'
> & {
  onChange?: OnChangeType<number | string>
  defaultValue?: DefaultValueType<T, K> | K
  prefix?: GetPrefixType<T> | string
  suffix?: GetSuffixType<T> | string
}

type ColumnTypeNumber<T> = {
  type: 'number'
  disabled?: boolean
  inputProps?: NumberInputPropsType<T, string | number> & { maxKey?: keyof T }
}

type ColumnTypeCheckbox<T> = {
  type: 'checkbox'
  inputProps?: Omit<CheckboxProps, 'onChange'> & {
    onChange?: OnChangeType<boolean>
    after?: ((item: T) => React.ReactNode) | React.ReactNode
    before?: ((item: T) => React.ReactNode) | React.ReactNode
  }
}

type ColumnTypeCalc<T> = {
  type: 'calc'
  defaultOnClick?: boolean
  render: (item: T, index: number) => React.ReactNode | string
}

export type GenericColumnType<T> = (
  | ColumnTypeText
  | ColumnTypeSelect
  | ColumnTypeNumber<T>
  | ColumnTypeCalc<T>
  | ColumnTypeCheckbox<T>
) &
  TransversalColumn<T>

type ColumnType<T> = (GenericColumnType<T> | false | null | undefined)[]

type TableProps<T> = {
  className?: string
  loading?: boolean
  minWidth?: number
  loadingMessage?: string
  placeholderSearch?: string
  data?: T[]
  keyId: keyof T
  noDataMessage?: string
  limitPage?: number
  hidePagination?: boolean
  alwaysHeader?: boolean
  columns: ColumnType<T>
  selectedRows?: string[]
  extraActions?: React.ReactNode
  extraRows?: React.ReactNode
  validateMenu?: (item: T) => boolean
  onDetail?: (item: T) => void
  contextMenu?: (item: T, index: number) => JSX.Element
  onDelete?: (ids: string[]) => void
  onSearch?: (query?: string, field?: string, startDate?: number, endDate?: number) => void
  onChangeSelected?: UseListStateHandlers<string>
}

const TableCustom = <T extends Record<string, any>>(props: TableProps<T>) => {
  const {
    className,
    loading,
    loadingMessage,
    placeholderSearch,
    keyId,
    noDataMessage,
    columns: columnsProps = [],
    data = [],
    selectedRows,
    onSearch,
    validateMenu,
    onDelete,
    onDetail,
    contextMenu,
    alwaysHeader,
    hidePagination,
    minWidth,
    extraActions,
    extraRows,
    limitPage = LIMIT_ITEMS,
    onChangeSelected,
  } = props

  const columns = columnsProps.filter(Boolean) as GenericColumnType<T>[]

  const isMobile = useIsMobile()

  const dataPagination = usePagination<T>(data, limitPage)

  const formTable = useForm<{
    query: string
    field: string
    startDate?: number
    endDate?: number
  }>({
    initialValues: {
      query: '',
      field: String(columns[1].key ?? columns[0].key),
    },
  })

  // React.useEffect(() => {
  //   return () => {
  //     formTable.reset()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const formValues = formTable.getValues()

  const isIndeterminate =
    selectedRows && selectedRows.length > 0 && selectedRows.length < dataPagination.pageData.length

  return (
    <div className={$('cd-mb-[1rem]', className)}>
      {Boolean(selectedRows?.length) && (
        <div className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-mb-[1rem] cd-grow cd-justify-end">
          {extraActions}
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="red"
            leftSection={<IconTrash size={18} />}
            size={UI.Size}
            variant="filled"
            onClick={() => selectedRows && onDelete?.(selectedRows)}
          >
            Eliminar
          </Button>
        </div>
      )}
      {onSearch && !selectedRows?.length && (
        <form
          className="cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow cd-mb-[1rem]"
          onSubmit={formTable.onSubmit(({ query, field, startDate, endDate }) => {
            onSearch(query, field, startDate, endDate)
          })}
        >
          <div className="md:cd-basis-[80%] cd-flex cd-flex-col md:cd-flex-row cd-gap-[1rem] cd-grow">
            <Input
              allowDeselect={false}
              className="md:cd-basis-[20%] cd-w-full"
              data={columns
                .filter(({ searchable }) => searchable)
                .map((column) => ({ label: column.label, value: column.key as string }))}
              placeholder="Campo"
              typeInput="select"
              {...formTable.getInputProps('field')}
              onChange={(value) => {
                formTable.setValues({ query: '', field: value ?? '' })
              }}
            />
            {formValues.field === 'date' && (
              <Input
                className="md:cd-basis-[80%] cd-w-full"
                placeholder="Escoge un rango de fechas"
                rightSection={
                  <IconX
                    size={18}
                    onClick={() => {
                      formTable.reset()
                      onSearch()
                    }}
                  />
                }
                type="range"
                typeInput="dateRangePicker"
                onChange={(value) => {
                  const castValue = value as unknown as [Date, Date]
                  const [startDate, endDate] = castValue

                  formTable.setFieldValue('startDate', moment(startDate).startOf('day').unix())
                  formTable.setFieldValue('endDate', moment(endDate).endOf('day').unix())
                }}
              />
            )}
            {formValues.field !== 'date' && (
              <Input
                className="md:cd-basis-[80%] cd-w-full"
                placeholder={placeholderSearch ?? 'Buscar'}
                rightSection={
                  Boolean(formTable.values.query.length) && (
                    <IconX
                      size={18}
                      onClick={() => {
                        formTable.setFieldValue('query', '')
                        onSearch()
                      }}
                    />
                  )
                }
                typeInput="text"
                {...formTable.getInputProps('query')}
              />
            )}
          </div>
          <Button
            fullWidth
            className="md:cd-basis-[20%]"
            color="blue"
            leftSection={<IconSearch size={18} />}
            size={UI.Size}
            type="submit"
            variant="filled"
          >
            Buscar
          </Button>
        </form>
      )}
      {loading && (
        <LoaderText className="cd-mt-[2rem]">
          <Text c="dimmed" className="cd-text-sm">
            {loadingMessage ?? 'Cargando datos'}
          </Text>
        </LoaderText>
      )}
      {!alwaysHeader && !loading && !dataPagination.pageData.length && (
        <Text c="dimmed" className="cd-text-center cd-text-base cd-mt-[2rem]">
          {noDataMessage ?? 'No se encontraron registros'}
        </Text>
      )}
      {((!loading && Boolean(dataPagination.pageData.length)) || alwaysHeader) && (
        <React.Fragment>
          <Paper withBorder>
            <Table.ScrollContainer
              minWidth={minWidth ? minWidth + (isMobile ? 450 : 0) : undefined}
              type="native"
            >
              <Table highlightOnHover striped withColumnBorders withRowBorders>
                <Table.Thead>
                  <Table.Tr>
                    {selectedRows && (
                      <Table.Th colSpan={1}>
                        <Checkbox
                          checked={
                            selectedRows.length === dataPagination.pageData.length &&
                            dataPagination.pageData.length > 0
                          }
                          indeterminate={isIndeterminate}
                          onChange={(event) => {
                            if (event.target.checked && !isIndeterminate) {
                              onChangeSelected?.setState(
                                dataPagination.pageData.map((item) => item[keyId]),
                              )
                            } else {
                              onChangeSelected?.setState([])
                            }
                          }}
                        />
                      </Table.Th>
                    )}
                    {columns.map((column, index) => (
                      <Table.Th
                        className={getAlign(column.align ?? 'left')}
                        key={index}
                        w={column.width}
                      >
                        {column.label}
                      </Table.Th>
                    ))}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{buildRows(dataPagination.pageData)}</Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Paper>
          {!hidePagination && (
            <div className="cd-flex cd-w-full cd-justify-center cd-mt-[1rem]">
              <Pagination
                siblings={2}
                total={dataPagination.totalPages}
                onChange={dataPagination.goToPage}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )

  function buildRows(data: T[]) {
    const rows = data.map((item, index) => (
      <Table.Tr
        className={$('cd-relative', onDetail && 'cd-cursor-pointer')}
        id={`row-index-${index}`}
        key={`row-main-${index}`}
      >
        {selectedRows && (
          <Table.Td colSpan={1} key={`column-checkbox-${index}`} width="1%">
            <Checkbox
              checked={selectedRows?.includes(item[keyId] as string)}
              onChange={(event) => {
                if (!selectedRows) return

                if (event.target.checked) {
                  onChangeSelected?.append(item[keyId] as string)
                } else {
                  const idx = selectedRows.indexOf(item[keyId] as string)
                  if (idx !== -1) {
                    onChangeSelected?.remove(idx)
                  }
                }
              }}
            />
          </Table.Td>
        )}
        {buildRowsColumns(columns, item, index)}
      </Table.Tr>
    ))

    if (extraRows) {
      rows.push(extraRows as React.ReactElement<typeof Table.Tr>)
    }

    return rows
  }

  function getAlign(align: 'left' | 'center' | 'right') {
    if (align === 'left') return 'cd-text-left'
    if (align === 'center') return 'cd-text-center'
    if (align === 'right') return 'cd-text-right'
  }

  function buildRowsColumns(columns: GenericColumnType<T>[], item: T, indexRow: number) {
    return columns.map((column, index) => {
      let trComp: React.ReactNode = null

      if (column.type === 'text') {
        trComp = (
          <Table.Td
            className={getAlign(column.align ?? 'left')}
            key={`column-${index}`}
            w={column.width}
            onClick={() => onDetail?.(item)}
          >
            {item[column.key]}
          </Table.Td>
        )
      } else if (column.type === 'checkbox') {
        const props = column.inputProps as ColumnTypeCheckbox<T>['inputProps']

        const after = typeof props?.after === 'function' ? props.after(item) : props?.after

        const before = typeof props?.before === 'function' ? props.before(item) : props?.before

        trComp = (
          <Table.Td
            className={getAlign(column.align ?? 'left')}
            key={`column-${index}`}
            w={column.width}
          >
            <div className="cd-flex cd-items-center cd-flex-row cd-justify-start cd-gap-x-[0.5rem]">
              {before}
              <Checkbox
                checked={item[column.key] as boolean}
                onChange={(event) => props?.onChange?.(event.target.checked, item[keyId] as string)}
              />
              {after}
            </div>
          </Table.Td>
        )
      } else if (column.type === 'number') {
        const props = column.inputProps as ColumnTypeNumber<T>['inputProps']

        let defaultValue: string | number | undefined = 0

        if (typeof props?.defaultValue === 'function') {
          defaultValue = props.defaultValue(item)
        } else {
          defaultValue = props?.defaultValue
        }

        let prefix = ''

        if (typeof props?.prefix === 'function') {
          prefix = props.prefix(item)
        } else {
          prefix = props?.prefix ?? ''
        }

        let suffix = ''

        if (typeof props?.suffix === 'function') {
          suffix = props.suffix(item)
        } else {
          suffix = props?.suffix ?? ''
        }

        let max = undefined

        if (column.inputProps?.maxKey) {
          max = item[column.inputProps.maxKey]
        }

        trComp = (
          <Table.Td
            className={getAlign(column.align ?? 'left')}
            key={`column-${index}`}
            w={column.width}
          >
            <Input
              typeInput="number"
              {...props}
              clampBehavior={max ? 'strict' : 'none'}
              defaultValue={defaultValue}
              disabled={column.disabled}
              max={max}
              prefix={prefix}
              suffix={suffix}
              onChange={(value) => props?.onChange?.(value, item[keyId] as string)}
            />
          </Table.Td>
        )
      } else if (column.type === 'calc') {
        trComp = (
          <Table.Td
            className={getAlign(column.align ?? 'left')}
            key={`column-${index}`}
            w={column.width}
            onClick={(e) => {
              if (column.defaultOnClick && onDetail) {
                e.preventDefault()
                e.stopPropagation()
                onDetail(item)
              }
            }}
          >
            {column.render(item, indexRow)}
          </Table.Td>
        )
      }

      if (contextMenu && validateMenu?.(item) && ['text', 'calc'].includes(column.type)) {
        return (
          <Menu
            disabled={Boolean(selectedRows?.length) || column.hideMenu}
            key={`menu-indx-${index}`}
            opened={selectedRows && selectedRows.length ? false : undefined}
            position="bottom-start"
            shadow="md"
            width={200}
            onChange={(opened) => {
              if (!opened || column.hideMenu) return

              const element = document.getElementById(`row-index-${indexRow}`)

              if (!element) return

              const rect = element.getBoundingClientRect()
              const scrollTop = window.scrollY || document.documentElement.scrollTop
              const top = rect.top + scrollTop - 100

              window.scrollTo({
                top,
                behavior: 'smooth',
              })
            }}
          >
            <Menu.Target>{trComp}</Menu.Target>
            {contextMenu(item, indexRow)}
          </Menu>
        )
      }

      return trComp
    })
  }
}

export default TableCustom
