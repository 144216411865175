import { ComboboxItem, ComboboxItemGroup, ComboboxParsedItem } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isEmpty = (errors: Record<string, any>) => {
  return Object.keys(errors).length === 0
}

export const invalidForm = (form: UseFormReturnType<any, any>) => {
  return !form.isDirty() || !isEmpty(form.errors)
}

type BuildSelectOptionsParams<T> = {
  data: T[]
  value: keyof T
  blackList?: any[]
  disabledKey?: keyof T
  groupBy?: keyof T
  groupMap?: Record<string, { label: string; value: string }>
  label: ((item: T) => string) | keyof T
}

type GroupedOptions = {
  group: string
  items: ComboboxItem[]
}

export const buildSelectOptions = <T>(params: BuildSelectOptionsParams<T>) => {
  const { data, value, label, groupBy, groupMap, disabledKey, blackList } = params

  if (groupBy) {
    const grouped = data.reduce(
      (acc, item) => {
        const group = item[groupBy as keyof T] as string

        if (!acc[group]) {
          acc[group] = []
        }

        acc[group].push({
          label: typeof label === 'function' ? label(item) : (item[label as keyof T] as string),
          value: item[value as keyof T] as string,
          disabled: disabledKey ? (item[disabledKey] as boolean) : blackList?.includes(item[value]),
        })

        return acc
      },
      {} as Record<string, ComboboxItem[]>,
    )

    const dataGroup: GroupedOptions[] = []

    for (const [group, items] of Object.entries(grouped)) {
      let groupKey = group

      if (groupMap && groupMap[group]) {
        groupKey = groupMap[group].label
      }

      dataGroup.push({ group: groupKey, items })
    }

    return dataGroup
  }

  return data.map((item) => ({
    label: typeof label === 'function' ? label(item) : (item[label as keyof T] as string),
    value: item[value as keyof T] as string,
    disabled: disabledKey ? (item[disabledKey] as boolean) : blackList?.includes(item[value]),
  }))
}

export const filterSelect = ({
  options,
  search,
  limit,
}: {
  options: ComboboxParsedItem[]
  search: string
  limit?: number
}) => {
  search = search.trim().toLowerCase()

  if (!search.length && limit) return options.slice(0, limit)

  const castedOptions = options as ComboboxItemGroup<ComboboxItem>[]

  if (castedOptions.at(0)?.group) {
    return castedOptions.filter((group) => {
      const filteredItems = group.items.filter(
        (item) =>
          item.label.toLowerCase().includes(search) || item.value.toLowerCase().includes(search),
      )

      return filteredItems.length ? { ...group, items: filteredItems } : null
    })
  }

  return (options as ComboboxItem[]).filter(
    (option) =>
      option.label.toLowerCase().includes(search.toLowerCase()) ||
      option.value.toLowerCase().includes(search),
  )
}
