import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { FORMAT_ID } from '@constants/receipt'

import { ReceiptSchema } from './receipt'
import { GetEntitySchema } from './network'

export const ReceiptBlockSchema = ReceiptSchema.extend({
  quantity: z.number().gt(0, 'La cantidad debe ser mayor a 0'),
})

export type ReceiptBlockType = z.infer<typeof ReceiptBlockSchema>

//Used when get all block
export const BlockBaseSchema = z.object({
  uid: z
    .string()
    .regex(
      FORMAT_ID,
      'ID debe ser en mayúsculas y opcionalmente seguido de un guión y números. Ej: AB, AB-1',
    )
    .toUpperCase(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(200, 'Descripción debe tener como máximo 200 caracteres').trim(),
  descriptionProforma: z
    .string()
    .max(750, 'Descripción de proforma debe tener como máximo 750 caracteres')
    .trim(),
  deleted: z.boolean().optional(),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type BlockBaseType = z.infer<typeof BlockBaseSchema>

//Used when get a block by id
export const BlockSchema = BlockBaseSchema.extend({
  receipts: z.array(ReceiptBlockSchema).min(1, 'Debe tener al menos un material'),
})

export type BlockType = z.infer<typeof BlockSchema>

// Internal use
export const BlockFirebaseSchema = BlockSchema.omit({
  receipts: true,
}).extend({
  receipts: z.array(z.custom<DocumentReference>()),
})

export type BlockFirebaseType = z.infer<typeof BlockFirebaseSchema>

//Create
export const CreateBlockSchema = BlockSchema.omit({
  updatedAt: true,
  receipts: true,
  createdAt: true,
}).extend({
  receipts: z.array(ReceiptBlockSchema),
})

export type CreateBlockType = z.infer<typeof CreateBlockSchema>

//Update
export const UpdateBlockSchema = z.object({
  current: CreateBlockSchema.partial(),
  previous: CreateBlockSchema.partial().extend({
    uid: z.string(),
  }),
})

export type UpdateBlockType = z.infer<typeof UpdateBlockSchema>

//Get blocks
export const GetBlocksSchema = GetEntitySchema

export type GetBlocksType = z.infer<typeof GetBlocksSchema>

//Delete receipts from block
export const DeleteReceiptsBlockSchema = z.object({
  blockID: z.string(),
  receiptIDs: z.array(z.string()),
})

export type DeleteReceiptsBlockType = z.infer<typeof DeleteReceiptsBlockSchema>
