import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import {
  doc,
  limit,
  query,
  where,
  getDoc,
  getDocs,
  orderBy,
  updateDoc,
  deleteDoc,
  collection,
  writeBatch,
  startAfter,
  runTransaction,
  QueryConstraint,
  DocumentReference,
  DocumentSnapshot,
} from 'firebase/firestore'

import type { UserType } from '@customTypes/user'

import { Logger } from '@utils/log'
import { ErrorCodes, ErrorService } from '@utils/error'
import { Collections } from '@constants/api'
import {
  RawMaterialFirebaseType,
  RawMaterialWarehouseType,
  GetRawMaterialsWarehouseType,
} from '@customTypes/rawMaterial'

import {
  CommitType,
  GetCommitsType,
  CommitBaseType,
  CreateCommitType,
  UpdateCommitType,
  CommitFirebaseType,
} from '@customTypes/commit'

import {
  WarehouseType,
  GetWarehousesType,
  WarehouseBaseType,
  CreateWarehouseType,
  UpdateWarehouseType,
  MoveRawMaterialsType,
  WarehouseFirebaseType,
  DeleteRawMaterialsFromWarehouseType,
} from '@customTypes/warehouse'

import { StockBaseType } from '@customTypes/stock'

import { GetToolsWarehouseType, ToolFirebaseType, ToolWarehouseType } from '@customTypes/tool'

import {
  CreateWarehouseToolType,
  DeleteToolsFromWarehouseToolType,
  GetWarehouseToolsType,
  MoveToolsType,
  UpdateWarehouseToolType,
  WarehouseToolBaseType,
  WarehouseToolFirebaseType,
  WarehouseToolType,
} from '@customTypes/warehouseTool'

import { StockToolBaseType } from '@customTypes/stockTool'

import {
  CommitToolBaseType,
  CommitToolFirebaseType,
  CommitToolType,
  CreateCommitToolType,
  GetCommitToolsType,
  UpdateCommitToolType,
} from '@customTypes/commitTool'

import WarehouseEntitiesDS from '@api/domain/ds/WarehouseEntitiesDS'

import { firestore, parseEntityToRef, parseRefToEntity, uploadAttachment } from '@utils/firebase'

import UserImpl from './UserImpl'
import ConstructionEntitiesImpl from './ConstructionEntitiesImpl'

class WarehouseEntitiesImpl extends WarehouseEntitiesDS {
  static instance: WarehouseEntitiesImpl

  private constructionEntitiesService = ConstructionEntitiesImpl.getInstance()
  private userService = UserImpl.getInstance()

  private constructor() {
    super()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new WarehouseEntitiesImpl()
    }

    return this.instance
  }

  //Warehouse Tool
  parseToWarehouseToolBase(warehouseToolFirebase: WarehouseToolFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt, inHouse } = warehouseToolFirebase

    const warehouseToolBase: WarehouseToolBaseType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      inHouse,
    }

    return warehouseToolBase
  }

  async parseToWarehouseTool(warehouseToolFirebase: WarehouseToolFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt, inHouse } = warehouseToolFirebase

    const tools: ToolWarehouseType[] = []

    for (const toolRef of warehouseToolFirebase.tools) {
      const toolData = await parseRefToEntity<ToolFirebaseType>(toolRef)

      tools.push(await this.constructionEntitiesService.parseToTool(uid, toolData))
    }

    const warehouseToolData: WarehouseToolType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      inHouse,
      tools,
    }

    return warehouseToolData
  }

  async getWarehouseTools(params: GetWarehouseToolsType) {
    try {
      const collectionRef = collection(firestore, Collections.WAREHOUSES_TOOLS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const warehouseToolsSnap = await getDocs(q)

      const warehouseToolsData = warehouseToolsSnap.docs.map((doc) =>
        doc.data(),
      ) as WarehouseToolFirebaseType[]

      const warehouseToolBase: WarehouseToolBaseType[] = []

      for (const warehouseTool of warehouseToolsData) {
        warehouseToolBase.push({
          uid: warehouseTool.uid,
          name: warehouseTool.name,
          inHouse: warehouseTool.inHouse,
          deleted: warehouseTool.deleted,
          description: warehouseTool.description,
          updatedAt: warehouseTool.updatedAt,
          createdAt: warehouseTool.createdAt,
        })
      }

      return warehouseToolBase
    } catch (error) {
      Logger.error('Error getting warehouse tools', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS)
    }
  }

  async getWarehouseToolById(id: string) {
    try {
      const docRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, id)

      const warehouseToolSnap = await parseRefToEntity<WarehouseToolFirebaseType>(docRef)

      const tools = await this.getToolsByWarehouse({ warehouseToolID: id })

      const warehouseToolData: WarehouseToolType = {
        uid: warehouseToolSnap.uid,
        name: warehouseToolSnap.name,
        description: warehouseToolSnap.description,
        deleted: warehouseToolSnap.deleted,
        updatedAt: warehouseToolSnap.updatedAt,
        createdAt: warehouseToolSnap.createdAt,
        inHouse: warehouseToolSnap.inHouse,
        tools,
      }

      return warehouseToolData
    } catch (error) {
      Logger.error('Error getting warehouse tool by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS)
    }
  }

  async getToolsByWarehouse(params: GetToolsWarehouseType) {
    try {
      const { warehouseToolID } = params

      const warehouseToolRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseToolID)

      const warehouseToolData = await parseRefToEntity<WarehouseToolFirebaseType>(warehouseToolRef)

      const tools: ToolWarehouseType[] = []

      for (const toolRef of warehouseToolData.tools) {
        const toolData = await parseRefToEntity<ToolFirebaseType>(toolRef)

        tools.push(await this.constructionEntitiesService.parseToTool(warehouseToolID, toolData))
      }

      return tools
    } catch (error) {
      Logger.error('Error getting tools by warehouse tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_TOOLS_BY_WAREHOUSE_TOOL)
    }
  }

  async createWarehouseTool(data: CreateWarehouseToolType) {
    try {
      const warehouseTool = await runTransaction<WarehouseToolType>(
        firestore,
        async (transaction) => {
          const uid = uuidv4()

          const tools: DocumentReference[] = []

          for (const tool of data.tools) {
            const docRef = doc(firestore, Collections.TOOLS.NAME, tool.uid)
            tools.push(docRef)

            const stockRef = doc(
              firestore,
              Collections.STOCKS_TOOLS.NAME,
              this.getStockId(uid, tool.uid),
            )

            const currentTimestamp = moment().unix()

            const stockData: StockToolBaseType = {
              warehouseToolID: uid,
              quantity: tool.stock,
              toolID: tool.uid,
              updatedAt: currentTimestamp,
              createdAt: currentTimestamp,
            }

            transaction.set(stockRef, stockData)
          }

          const currentTimestamp = moment().unix()

          const newWarehouseTool: WarehouseToolFirebaseType = {
            ...data,
            uid,
            tools,
            deleted: false,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          transaction.set(
            doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, newWarehouseTool.uid),
            newWarehouseTool,
          )

          return {
            ...data,
            uid: newWarehouseTool.uid,
            deleted: newWarehouseTool.deleted,
            updatedAt: newWarehouseTool.updatedAt,
          } as WarehouseToolType
        },
      )

      return warehouseTool
    } catch (error) {
      Logger.error('Error creating warehouse tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_WAREHOUSE_TOOL)
    }
  }

  async updateWarehouseTool(data: UpdateWarehouseToolType) {
    try {
      await runTransaction(firestore, async (transaction) => {
        const tools: DocumentReference[] = []

        if (data.tools) {
          for (const tool of data.tools) {
            const docRef = doc(firestore, Collections.TOOLS.NAME, tool.uid)
            tools.push(docRef)
          }
        }

        const warehouseToolFirebaseData: Partial<WarehouseToolFirebaseType> = {
          name: data.name,
          description: data.description,
          deleted: data.deleted,
          tools,
          updatedAt: moment().unix(),
        }

        transaction.update(
          doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, data.uid),
          warehouseToolFirebaseData,
        )
      })
    } catch (error) {
      Logger.error('Error updating warehouse tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_WAREHOUSE_TOOL)
    }
  }

  async deleteWarehouseTool(id: string) {
    try {
      const docRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSE_TOOL)
    }
  }

  async deleteWarehouseTools(ids: string[]) {
    try {
      for (const id of ids) {
        const docRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, id)

        await deleteDoc(docRef)
      }
    } catch (error) {
      Logger.error('Error deleting warehouses', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSE_TOOLS)
    }
  }

  async moveToolsFromWarehouse(params: MoveToolsType) {
    try {
      const user = await this.userService.getUser()

      if (!user) {
        throw ErrorService.get(ErrorCodes.ERROR_SESSION_EXPIRED)
      }

      const { tools, warehouseToolFromID, warehouseToolToID } = params

      const rmCommitRefs: CommitToolFirebaseType['tools'] = []

      await runTransaction(firestore, async (transaction) => {
        const warehouseToolFromRef = doc(
          firestore,
          Collections.WAREHOUSES_TOOLS.NAME,
          warehouseToolFromID,
        )
        const warehouseToolToRef = doc(
          firestore,
          Collections.WAREHOUSES_TOOLS.NAME,
          warehouseToolToID,
        )

        const warehouseToolFromSnap = await getDoc(warehouseToolFromRef)
        const warehouseToolToSnap = await getDoc(warehouseToolToRef)

        //let changeFromWarehouse = false
        const warehouseToolFromData = warehouseToolFromSnap.data() as WarehouseToolFirebaseType
        let changeToWarehouse = false
        const warehouseToolToData = warehouseToolToSnap.data() as WarehouseToolFirebaseType

        const currentTimestamp = moment().unix()

        for (const { toolID, quantity } of tools) {
          const stockFromRef = doc(
            firestore,
            Collections.STOCKS_TOOLS.NAME,
            this.getStockId(warehouseToolFromID, toolID),
          )

          const stockFromSnap = await transaction.get(stockFromRef)

          const stockFromData = stockFromSnap.data() as StockToolBaseType

          const stockToRef = doc(
            firestore,
            Collections.STOCKS_TOOLS.NAME,
            this.getStockId(warehouseToolToID, toolID),
          )

          const stockToSnap = await transaction.get(stockToRef)

          const stockToData = stockToSnap.data() as StockToolBaseType

          if (stockFromData.quantity < quantity) {
            throw ErrorService.get(ErrorCodes.ERROR_NOT_ENOUGH_STOCK)
          }

          transaction.update(stockFromRef, {
            quantity: stockFromData.quantity - quantity,
            updatedAt: moment().unix(),
          })

          if (stockToSnap.exists()) {
            transaction.update(stockToRef, {
              quantity: stockToData.quantity + quantity,
              updatedAt: moment().unix(),
            })
          } else {
            transaction.set(stockToRef, {
              warehouseToolID: warehouseToolToID,
              quantity,
              toolID,
              updatedAt: moment().unix(),
              createdAt: moment().unix(),
            })

            changeToWarehouse = true
            warehouseToolToData.tools.push(doc(firestore, Collections.TOOLS.NAME, toolID))
          }

          rmCommitRefs.push({
            ref: doc(firestore, Collections.TOOLS.NAME, toolID),
            quantity,
          })
        }

        const newTargetCommit: CommitToolFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: parseEntityToRef(user),
          tools: rmCommitRefs,
          message: `[Sistema] Movimiento de herramientas desde ${warehouseToolFromData.name}`,
          warehouse: doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseToolToID),
        }

        transaction.set(
          doc(firestore, Collections.COMMIT_TOOLS.NAME, newTargetCommit.uid),
          newTargetCommit,
        )

        const newSourceCommit: CommitToolFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: parseEntityToRef(user),
          tools: rmCommitRefs,
          message: `[Sistema] Movimiento de herramientas hacia ${warehouseToolToData.name}`,
          warehouse: doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseToolFromID),
        }

        transaction.set(
          doc(firestore, Collections.COMMIT_TOOLS.NAME, newSourceCommit.uid),
          newSourceCommit,
        )

        if (changeToWarehouse) {
          transaction.update(warehouseToolToRef, {
            tools: warehouseToolToData.tools,
            updatedAt: moment().unix(),
          })
        }
      })
    } catch (error) {
      Logger.error('Error moving tools to warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_MOVING_TOOLS_TO_WAREHOUSE)
    }
  }

  async deleteToolsFromWarehouse(params: DeleteToolsFromWarehouseToolType) {
    try {
      const { toolIDs, warehouseToolID } = params

      await runTransaction(firestore, async (transaction) => {
        const warehouseToolRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseToolID)

        const warehouseToolSnap = await transaction.get(warehouseToolRef)

        const warehouseToolData = warehouseToolSnap.data() as WarehouseToolFirebaseType

        for (const toolID of toolIDs) {
          const stockRef = doc(
            firestore,
            Collections.STOCKS_TOOLS.NAME,
            this.getStockId(warehouseToolID, toolID),
          )

          transaction.delete(stockRef)

          const index = warehouseToolData.tools.findIndex((tool) => tool.id === toolID)

          if (index !== -1) {
            warehouseToolData.tools.splice(index, 1)
          }
        }

        transaction.update(warehouseToolRef, {
          tools: warehouseToolData.tools,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting tools from warehouse tool', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_TOOLS_FROM_WAREHOUSE_TOOL)
    }
  }

  //Warehouse
  parseToWarehouseBase(warehouseFirebase: WarehouseFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt, inHouse } = warehouseFirebase

    const warehouseBase: WarehouseBaseType = {
      uid,
      name,
      deleted,
      updatedAt,
      description,
      createdAt,
      inHouse,
    }

    return warehouseBase
  }

  async parseToWarehouse(warehouseFirebase: WarehouseFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt, inHouse } = warehouseFirebase

    const rawMaterials: RawMaterialWarehouseType[] = []

    for (const rawMaterialRef of warehouseFirebase.rawMaterials) {
      const rawMaterialData = await parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

      rawMaterials.push(
        await this.constructionEntitiesService.parseToRawMaterial(uid, rawMaterialData),
      )
    }

    const warehouseData: WarehouseType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      inHouse,
      rawMaterials,
    }

    return warehouseData
  }

  async getWarehouses(params: GetWarehousesType) {
    try {
      const collectionRef = collection(firestore, Collections.WAREHOUSES.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const warehousesSnap = await getDocs(q)

      const warehousesData = warehousesSnap.docs.map((doc) => doc.data()) as WarehouseFirebaseType[]

      const warehouseBase: WarehouseBaseType[] = []

      for (const warehouse of warehousesData) {
        warehouseBase.push({
          uid: warehouse.uid,
          name: warehouse.name,
          inHouse: warehouse.inHouse,
          deleted: warehouse.deleted,
          description: warehouse.description,
          updatedAt: warehouse.updatedAt,
          createdAt: warehouse.createdAt,
        })
      }

      return warehouseBase
    } catch (error) {
      Logger.error('Error getting warehouses', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_WAREHOUSES)
    }
  }

  async getWarehouseById(id: string) {
    try {
      const docRef = doc(firestore, Collections.WAREHOUSES.NAME, id)

      const warehouseSnap = await parseRefToEntity<WarehouseFirebaseType>(docRef)

      const rawMaterials = await this.getMaterialsByWarehouse({ warehouseID: id })

      const warehouseData: WarehouseType = {
        uid: warehouseSnap.uid,
        name: warehouseSnap.name,
        inHouse: warehouseSnap.inHouse,
        description: warehouseSnap.description,
        updatedAt: warehouseSnap.updatedAt,
        createdAt: warehouseSnap.createdAt,
        deleted: warehouseSnap.deleted,
        rawMaterials: rawMaterials,
      }

      return warehouseData
    } catch (error) {
      Logger.error('Error getting warehouse by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_WAREHOUSES)
    }
  }

  async getMaterialsByWarehouse(params: GetRawMaterialsWarehouseType) {
    try {
      const { warehouseID } = params

      const warehouseRef = doc(firestore, Collections.WAREHOUSES.NAME, warehouseID)

      const warehouseData = await parseRefToEntity<WarehouseFirebaseType>(warehouseRef)

      const rawMaterials: RawMaterialWarehouseType[] = []

      for (const rawMaterialRef of warehouseData.rawMaterials) {
        const rawMaterialData = await parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

        rawMaterials.push(
          await this.constructionEntitiesService.parseToRawMaterial(warehouseID, rawMaterialData),
        )
      }

      return rawMaterials
    } catch (error) {
      Logger.error('Error getting materials by warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_MATERIALS_BY_WAREHOUSE)
    }
  }

  async createWarehouse(data: CreateWarehouseType) {
    try {
      const warehouse = await runTransaction<WarehouseType>(firestore, async (transaction) => {
        const uid = uuidv4()

        const rawMaterials: DocumentReference[] = []

        for (const rawMaterial of data.rawMaterials) {
          const docRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)
          rawMaterials.push(docRef)

          const stockRef = doc(
            firestore,
            Collections.STOCKS.NAME,
            this.getStockId(uid, rawMaterial.uid),
          )

          const currentTimestamp = moment().unix()

          const stockData: StockBaseType = {
            warehouseID: uid,
            quantity: rawMaterial.stock,
            rawMaterialID: rawMaterial.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          transaction.set(stockRef, stockData)
        }

        const currentTimestamp = moment().unix()

        const newWarehouse: WarehouseFirebaseType = {
          ...data,
          uid,
          rawMaterials,
          deleted: false,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        transaction.set(doc(firestore, Collections.WAREHOUSES.NAME, newWarehouse.uid), newWarehouse)

        return {
          ...data,
          uid: newWarehouse.uid,
          deleted: newWarehouse.deleted,
          updatedAt: newWarehouse.updatedAt,
        } as WarehouseType
      })

      return warehouse
    } catch (error) {
      Logger.error('Error creating warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_WAREHOUSE)
    }
  }

  async updateWarehouse(data: UpdateWarehouseType) {
    try {
      if (data.rawMaterials) {
        console.error("DON'T USE UPDATE WAREHOUSE TO UPDATE RAW MATERIALS")
        throw ErrorService.get(ErrorCodes.ERROR_UPDATING_WAREHOUSE)
      }

      const warehouseFirebaseData: Partial<WarehouseFirebaseType> = {
        name: data.name,
        deleted: data.deleted,
        updatedAt: moment().unix(),
        description: data.description,
      }

      const docRef = doc(firestore, Collections.WAREHOUSES.NAME, data.uid)

      await updateDoc(docRef, warehouseFirebaseData)
    } catch (error) {
      Logger.error('Error updating warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_WAREHOUSE)
    }
  }

  async deleteWarehouse(id: string) {
    try {
      const docRef = doc(firestore, Collections.WAREHOUSES.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSE)
    }
  }

  async deleteWarehouses(ids: string[]) {
    try {
      const batch = writeBatch(firestore)

      ids.forEach((id) => {
        const docRef = doc(firestore, Collections.WAREHOUSES.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting warehouses', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSES)
    }
  }

  async moveRawMaterials(params: MoveRawMaterialsType) {
    try {
      const user = await this.userService.getUser()

      if (!user) {
        throw ErrorService.get(ErrorCodes.ERROR_SESSION_EXPIRED)
      }

      const { rawMaterials, warehouseFromID, warehouseToID } = params

      const rmCommitRefs: CommitFirebaseType['rawMaterials'] = []

      await runTransaction(firestore, async (transaction) => {
        const warehouseFromRef = doc(firestore, Collections.WAREHOUSES.NAME, warehouseFromID)
        const warehouseToRef = doc(firestore, Collections.WAREHOUSES.NAME, warehouseToID)

        const warehouseFromSnap = await getDoc(warehouseFromRef)
        const warehouseToSnap = await getDoc(warehouseToRef)

        //let changeFromWarehouse = false
        const warehouseFromData = warehouseFromSnap.data() as WarehouseFirebaseType
        let changeToWarehouse = false
        const warehouseToData = warehouseToSnap.data() as WarehouseFirebaseType

        const currentTimestamp = moment().unix()

        for (const { rawMaterialID, quantity } of rawMaterials) {
          const stockFromRef = doc(
            firestore,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseFromID, rawMaterialID),
          )

          const stockFromSnap = await transaction.get(stockFromRef)

          const stockFromData = stockFromSnap.data() as StockBaseType

          const stockToRef = doc(
            firestore,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseToID, rawMaterialID),
          )

          const stockToSnap = await transaction.get(stockToRef)

          const stockToData = stockToSnap.data() as StockBaseType

          if (stockFromData.quantity < quantity) {
            throw ErrorService.get(ErrorCodes.ERROR_NOT_ENOUGH_STOCK)
          }

          transaction.update(stockFromRef, {
            quantity: stockFromData.quantity - quantity,
            updatedAt: moment().unix(),
          })

          if (stockToSnap.exists()) {
            transaction.update(stockToRef, {
              quantity: stockToData.quantity + quantity,
              updatedAt: moment().unix(),
            })
          } else {
            transaction.set(stockToRef, {
              warehouseID: warehouseToID,
              rawMaterialID,
              quantity,
              createdAt: moment().unix(),
              updatedAt: moment().unix(),
            })

            changeToWarehouse = true
            warehouseToData.rawMaterials.push(
              doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterialID),
            )
          }

          rmCommitRefs.push({
            ref: doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterialID),
            quantity,
          })
        }

        const newTargetCommit: CommitFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: parseEntityToRef(user),
          rawMaterials: rmCommitRefs,
          message: `[Sistema] Movimiento de materiales desde ${warehouseFromData.name}`,
          warehouse: doc(firestore, Collections.WAREHOUSES.NAME, warehouseToID),
        }

        transaction.set(
          doc(firestore, Collections.COMMITS.NAME, newTargetCommit.uid),
          newTargetCommit,
        )

        const newSourceCommit: CommitFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: parseEntityToRef(user),
          rawMaterials: rmCommitRefs,
          message: `[Sistema] Movimiento de materiales hacia ${warehouseToData.name}`,
          warehouse: doc(firestore, Collections.WAREHOUSES.NAME, warehouseFromID),
        }

        transaction.set(
          doc(firestore, Collections.COMMITS.NAME, newSourceCommit.uid),
          newSourceCommit,
        )

        if (changeToWarehouse) {
          transaction.update(warehouseToRef, {
            rawMaterials: warehouseToData.rawMaterials,
            updatedAt: moment().unix(),
          })
        }
      })
    } catch (error) {
      Logger.error('Error moving raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_MOVING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterialsFromWarehouse(
    params: DeleteRawMaterialsFromWarehouseType,
  ): Promise<void> {
    try {
      const { rawMaterialIDs, warehouseID } = params

      await runTransaction(firestore, async (transaction) => {
        const warehouseRef = doc(firestore, Collections.WAREHOUSES.NAME, warehouseID)

        const warehouseSnap = await transaction.get(warehouseRef)

        const warehouseData = warehouseSnap.data() as WarehouseFirebaseType

        for (const rawMaterialID of rawMaterialIDs) {
          const stockRef = doc(
            firestore,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseID, rawMaterialID),
          )

          transaction.delete(stockRef)

          const index = warehouseData.rawMaterials.findIndex((rm) => rm.id === rawMaterialID)

          if (index !== -1) {
            warehouseData.rawMaterials.splice(index, 1)
          }
        }

        transaction.update(warehouseRef, {
          rawMaterials: warehouseData.rawMaterials,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting raw materials from warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS_FROM_WAREHOUSE)
    }
  }

  //Commits
  getStockId(entityID: string, rawMaterialID: string) {
    return `${entityID}-${rawMaterialID}`
  }

  async getCommits(params: GetCommitsType) {
    try {
      const collectionRef = collection(firestore, Collections.COMMITS.NAME)

      const queries: QueryConstraint[] = [
        where('warehouse', '==', doc(firestore, Collections.WAREHOUSES.NAME, params.warehouseID)),
      ]

      if (params.field && params.query) {
        if (params.startDate && params.endDate) {
          queries.push(where(params.field, '>=', params.startDate))
          queries.push(where(params.field, '<=', params.endDate))
        }
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const commitsSnap = await getDocs(q)

      const commitsFirebaseData = commitsSnap.docs.map((doc) => doc.data()) as CommitFirebaseType[]

      const commitData: CommitBaseType[] = []

      for (let idx = 0; idx < commitsFirebaseData.length; idx++) {
        const commit = commitsFirebaseData[idx]

        const user = await parseRefToEntity<UserType>(commit.user as DocumentReference)

        const rawMaterials: CommitBaseType['rawMaterials'] = []

        for (const rawMaterial of commit.rawMaterials) {
          const rawMaterialFirebaseData = await parseRefToEntity<RawMaterialFirebaseType>(
            rawMaterial.ref,
          )

          rawMaterials.push({
            ...this.constructionEntitiesService.parseToRawMaterialBase(rawMaterialFirebaseData),
            quantity: rawMaterial.quantity,
          })
        }

        commitData.push({
          user,
          uid: commit.uid,
          action: commit.action,
          message: commit.message,
          updatedAt: commit.updatedAt,
          rawMaterials,
          createdAt: commit.createdAt,
          attachment: commit.attachment,
        })
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async getCommitById(id: string) {
    try {
      const docRef = doc(firestore, Collections.COMMITS.NAME, id)

      const commitSnap = await getDoc(docRef)

      const commitFirebaseData = commitSnap.data() as CommitFirebaseType

      const user = await parseRefToEntity<UserType>(commitFirebaseData.user)

      const rawMaterials: CommitBaseType['rawMaterials'] = []

      for (const rawMaterial of commitFirebaseData.rawMaterials) {
        const rawMaterialFirebaseData = await parseRefToEntity<RawMaterialFirebaseType>(
          rawMaterial.ref,
        )

        rawMaterials.push({
          ...this.constructionEntitiesService.parseToRawMaterialBase(rawMaterialFirebaseData),
          quantity: rawMaterial.quantity,
        })
      }

      const warehouseFirebaseData = await parseRefToEntity<WarehouseFirebaseType>(
        commitFirebaseData.warehouse,
      )

      const warehouseBaseData = this.parseToWarehouseBase(warehouseFirebaseData)

      const commitData: CommitType = {
        uid: commitFirebaseData.uid,
        action: commitFirebaseData.action,
        message: commitFirebaseData.message,
        updatedAt: commitFirebaseData.updatedAt,
        createdAt: commitFirebaseData.createdAt,
        attachment: commitFirebaseData.attachment,
        warehouse: warehouseBaseData,
        rawMaterials,
        user,
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commit by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async createCommit(params: CreateCommitType) {
    try {
      const { warehouseID, commit } = params

      const commitData = await runTransaction<CommitBaseType>(firestore, async (transaction) => {
        const currentUser = await this.userService.getUser()

        if (!currentUser) {
          throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
        }

        const userRef = parseEntityToRef<UserType>(currentUser)

        const userData = await parseRefToEntity<UserType>(userRef)

        const warehouseRef = doc(firestore, Collections.WAREHOUSES.NAME, warehouseID)

        if (commit.attachment) {
          commit.attachment = await uploadAttachment({
            entity: 'commit',
            entityId: warehouseID,
            base64: commit.attachment,
          })
        }

        const rawMaterialFirebase: CommitFirebaseType['rawMaterials'] = []

        for (const rawMaterial of commit.rawMaterials) {
          const rawMaterialRef = doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)

          rawMaterialFirebase.push({
            ref: rawMaterialRef,
            quantity: rawMaterial.quantity,
          })
        }

        const currentTimestamp = moment().unix()

        const commitFirebase: CommitFirebaseType = {
          uid: uuidv4(),
          action: commit.action,
          message: commit.message,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          attachment: commit.attachment,
          rawMaterials: rawMaterialFirebase,
          warehouse: warehouseRef,
          user: userRef,
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number; uid: string }[] = []

        for (const rawMaterial of commit.rawMaterials) {
          const { uid: rawMaterialID, quantity } = rawMaterial

          const stockID = this.getStockId(warehouseID, rawMaterialID)

          const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

          const stockSnap = await transaction.get(stockRef)

          stockSnaps.push({ snap: stockSnap, quantity, uid: rawMaterialID })
        }

        //Update stock quantity
        for (const item of stockSnaps) {
          const { snap, quantity, uid } = item

          if (commit.action === 'consume') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              transaction.update(snap.ref, { quantity: stockData.quantity - quantity })
            }
          } else if (commit.action === 'refill') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
            } else {
              const stockData: StockBaseType = {
                quantity,
                warehouseID,
                rawMaterialID: uid,
                updatedAt: currentTimestamp,
                createdAt: currentTimestamp,
              }

              transaction.set(snap.ref, stockData)
            }
          }
        }

        transaction.set(
          doc(firestore, Collections.COMMITS.NAME, commitFirebase.uid),
          commitFirebase,
        )

        const commitData: CommitBaseType = {
          uid: commitFirebase.uid,
          action: commitFirebase.action,
          message: commitFirebase.message,
          updatedAt: commitFirebase.updatedAt,
          createdAt: commitFirebase.createdAt,
          attachment: commitFirebase.attachment,
          rawMaterials: commit.rawMaterials,
          user: userData,
        }

        return commitData
      })

      return commitData
    } catch (error) {
      Logger.error('Error creating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_COMMIT)
    }
  }

  async updateCommit(params: UpdateCommitType) {
    try {
      const { previous, current, warehouseID } = params

      await runTransaction(firestore, async (transaction) => {
        const newData: Partial<CommitFirebaseType> = {
          rawMaterials: [],
        }

        if (current.message && current.message !== previous.message) {
          newData.message = current.message
        }
        if (current.action && current.action !== previous.action) {
          newData.action = current.action
        }
        if (current.attachment && current.attachment !== previous.attachment) {
          newData.attachment = await uploadAttachment({
            entity: 'commit',
            entityId: warehouseID,
            base64: current.attachment,
          })
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number; previousQty: number }[] = []

        for (const currentData of current.rawMaterials) {
          const previousData = previous.rawMaterials.find((rm) => rm.uid === currentData.uid)

          const previousQty = previousData?.quantity || 0

          if (currentData.quantity && currentData.quantity !== previousQty) {
            const stockID = this.getStockId(warehouseID, currentData.uid)

            newData.rawMaterials?.push({
              ref: doc(firestore, Collections.RAW_MATERIALS.NAME, currentData.uid),
              quantity: currentData.quantity,
            })

            if (current.action === 'consume') {
              const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            } else if (current.action === 'refill') {
              const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity, previousQty } = item

          if (current.action === 'consume') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              const tempStock = stockData.quantity + previousQty

              transaction.update(snap.ref, { quantity: tempStock - quantity })
            }
          } else if (current.action === 'refill') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              const tempStock = stockData.quantity - previousQty

              transaction.update(snap.ref, { quantity: tempStock + quantity })
            }
          }
        }

        if (Object.keys(newData).length === 0) {
          throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
        }

        const docRef = doc(firestore, Collections.COMMITS.NAME, previous.uid)

        transaction.update(docRef, newData)
      })
    } catch (error) {
      Logger.error('Error updating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_COMMIT)
    }
  }

  async deleteCommit(id: string) {
    try {
      const docRef = doc(firestore, Collections.COMMITS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMIT)
    }
  }

  async deleteCommits(ids: string[]) {
    try {
      await runTransaction(firestore, async (transaction) => {
        const commitSnaps: DocumentSnapshot[] = []

        for (const id of ids) {
          const docRef = doc(firestore, Collections.COMMITS.NAME, id)

          const commitSnap = await transaction.get(docRef)

          if (commitSnap.exists()) {
            commitSnaps.push(commitSnap)
          }
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number }[] = []

        for (const commitSnap of commitSnaps) {
          const commitData = commitSnap.data() as CommitFirebaseType

          for (const rawMaterial of commitData.rawMaterials) {
            const stockID = this.getStockId(commitData.warehouse.id, rawMaterial.ref.id)

            const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

            const stockSnap = await transaction.get(stockRef)

            if (stockSnap.exists()) {
              stockSnaps.push({ snap: stockSnap, quantity: rawMaterial.quantity })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity } = item

          const stockData = snap.data() as StockBaseType

          transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
        }

        for (const commitSnap of commitSnaps) {
          transaction.delete(commitSnap.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMITS)
    }
  }

  //Commit Tools
  async getCommitsTool(params: GetCommitToolsType) {
    try {
      const collectionRef = collection(firestore, Collections.COMMIT_TOOLS.NAME)

      const queries: QueryConstraint[] = [
        where(
          'warehouse',
          '==',
          doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, params.warehouseID),
        ),
      ]

      if (params.field && params.query) {
        if (params.startDate && params.endDate) {
          queries.push(where(params.field, '>=', params.startDate))
          queries.push(where(params.field, '<=', params.endDate))
        }
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const commitsSnap = await getDocs(q)

      const commitsFirebaseData = commitsSnap.docs.map((doc) =>
        doc.data(),
      ) as CommitToolFirebaseType[]

      const commitData: CommitToolBaseType[] = []

      for (let idx = 0; idx < commitsFirebaseData.length; idx++) {
        const commit = commitsFirebaseData[idx]

        const user = await parseRefToEntity<UserType>(commit.user as DocumentReference)

        const tools: CommitToolBaseType['tools'] = []

        for (const tool of commit.tools) {
          const toolFirebaseData = await parseRefToEntity<RawMaterialFirebaseType>(tool.ref)

          tools.push({
            ...this.constructionEntitiesService.parseToRawMaterialBase(toolFirebaseData),
            quantity: tool.quantity,
          })
        }

        commitData.push({
          user,
          uid: commit.uid,
          action: commit.action,
          message: commit.message,
          updatedAt: commit.updatedAt,
          tools,
          createdAt: commit.createdAt,
          attachment: commit.attachment,
        })
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async getCommitToolById(id: string) {
    try {
      const docRef = doc(firestore, Collections.COMMIT_TOOLS.NAME, id)

      const commitSnap = await getDoc(docRef)

      const commitFirebaseData = commitSnap.data() as CommitToolFirebaseType

      const user = await parseRefToEntity<UserType>(commitFirebaseData.user)

      const tools: CommitToolBaseType['tools'] = []

      for (const tool of commitFirebaseData.tools) {
        const toolFirebaseData = await parseRefToEntity<RawMaterialFirebaseType>(tool.ref)

        tools.push({
          ...this.constructionEntitiesService.parseToRawMaterialBase(toolFirebaseData),
          quantity: tool.quantity,
        })
      }

      const warehouseFirebaseData = await parseRefToEntity<WarehouseFirebaseType>(
        commitFirebaseData.warehouse,
      )

      const warehouseBaseData = this.parseToWarehouseBase(warehouseFirebaseData)

      const commitData: CommitToolType = {
        uid: commitFirebaseData.uid,
        action: commitFirebaseData.action,
        message: commitFirebaseData.message,
        updatedAt: commitFirebaseData.updatedAt,
        createdAt: commitFirebaseData.createdAt,
        attachment: commitFirebaseData.attachment,
        warehouse: warehouseBaseData,
        tools,
        user,
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commit by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async createCommitTool(params: CreateCommitToolType) {
    try {
      const { warehouseToolID, commit } = params

      const commitData = await runTransaction<CommitToolBaseType>(
        firestore,
        async (transaction) => {
          const currentUser = await this.userService.getUser()

          if (!currentUser) {
            throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
          }

          const userRef = parseEntityToRef<UserType>(currentUser)

          const userData = await parseRefToEntity<UserType>(userRef)

          const warehouseRef = doc(firestore, Collections.WAREHOUSES_TOOLS.NAME, warehouseToolID)

          if (commit.attachment) {
            commit.attachment = await uploadAttachment({
              entity: 'commitTool',
              entityId: warehouseToolID,
              base64: commit.attachment,
            })
          }

          const toolsFirebase: CommitToolFirebaseType['tools'] = []

          for (const tool of commit.tools) {
            const toolRef = doc(firestore, Collections.RAW_MATERIALS.NAME, tool.uid)

            toolsFirebase.push({
              ref: toolRef,
              quantity: tool.quantity,
            })
          }

          const currentTimestamp = moment().unix()

          const commitFirebase: CommitFirebaseType = {
            uid: uuidv4(),
            action: commit.action,
            message: commit.message,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
            attachment: commit.attachment,
            rawMaterials: toolsFirebase,
            warehouse: warehouseRef,
            user: userRef,
          }

          const stockSnaps: { snap: DocumentSnapshot; quantity: number; uid: string }[] = []

          for (const tool of commit.tools) {
            const { uid: rawMaterialID, quantity } = tool

            const stockID = this.getStockId(warehouseToolID, rawMaterialID)

            const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

            const stockSnap = await transaction.get(stockRef)

            stockSnaps.push({ snap: stockSnap, quantity, uid: rawMaterialID })
          }

          //Update stock quantity
          for (const item of stockSnaps) {
            const { snap, quantity, uid } = item

            if (commit.action === 'consume') {
              if (snap.exists()) {
                const stockData = snap.data() as StockToolBaseType

                transaction.update(snap.ref, { quantity: stockData.quantity - quantity })
              }
            } else if (commit.action === 'refill') {
              if (snap.exists()) {
                const stockData = snap.data() as StockToolBaseType

                transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
              } else {
                const stockData: StockToolBaseType = {
                  quantity,
                  warehouseToolID,
                  toolID: uid,
                  updatedAt: currentTimestamp,
                  createdAt: currentTimestamp,
                }

                transaction.set(snap.ref, stockData)
              }
            }
          }

          transaction.set(
            doc(firestore, Collections.COMMITS.NAME, commitFirebase.uid),
            commitFirebase,
          )

          const commitData: CommitToolBaseType = {
            uid: commitFirebase.uid,
            action: commitFirebase.action,
            message: commitFirebase.message,
            updatedAt: commitFirebase.updatedAt,
            createdAt: commitFirebase.createdAt,
            attachment: commitFirebase.attachment,
            tools: commit.tools,
            user: userData,
          }

          return commitData
        },
      )

      return commitData
    } catch (error) {
      Logger.error('Error creating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_COMMIT)
    }
  }

  async updateCommitTool(params: UpdateCommitToolType) {
    try {
      const { previous, current, warehouseToolID } = params

      await runTransaction(firestore, async (transaction) => {
        const newData: Partial<CommitToolFirebaseType> = {
          tools: [],
        }

        if (current.message && current.message !== previous.message) {
          newData.message = current.message
        }
        if (current.action && current.action !== previous.action) {
          newData.action = current.action
        }
        if (current.attachment && current.attachment !== previous.attachment) {
          newData.attachment = await uploadAttachment({
            entity: 'commitTool',
            entityId: warehouseToolID,
            base64: current.attachment,
          })
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number; previousQty: number }[] = []

        for (const currentData of current.tools) {
          const previousData = previous.tools.find((rm) => rm.uid === currentData.uid)

          const previousQty = previousData?.quantity || 0

          if (currentData.quantity && currentData.quantity !== previousQty) {
            const stockID = this.getStockId(warehouseToolID, currentData.uid)

            newData.tools?.push({
              ref: doc(firestore, Collections.RAW_MATERIALS.NAME, currentData.uid),
              quantity: currentData.quantity,
            })

            if (current.action === 'consume') {
              const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            } else if (current.action === 'refill') {
              const stockRef = doc(firestore, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity, previousQty } = item

          if (current.action === 'consume') {
            if (snap.exists()) {
              const stockData = snap.data() as StockToolBaseType

              const tempStock = stockData.quantity + previousQty

              transaction.update(snap.ref, { quantity: tempStock - quantity })
            }
          } else if (current.action === 'refill') {
            if (snap.exists()) {
              const stockData = snap.data() as StockToolBaseType

              const tempStock = stockData.quantity - previousQty

              transaction.update(snap.ref, { quantity: tempStock + quantity })
            }
          }
        }

        if (Object.keys(newData).length === 0) {
          throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
        }

        const docRef = doc(firestore, Collections.COMMITS.NAME, previous.uid)

        transaction.update(docRef, newData)
      })
    } catch (error) {
      Logger.error('Error updating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_COMMIT)
    }
  }

  async deleteCommitTool(id: string) {
    try {
      const docRef = doc(firestore, Collections.COMMIT_TOOLS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMIT)
    }
  }

  async deleteCommitsTool(ids: string[]) {
    try {
      await runTransaction(firestore, async (transaction) => {
        const commitSnaps: DocumentSnapshot[] = []

        for (const id of ids) {
          const docRef = doc(firestore, Collections.COMMIT_TOOLS.NAME, id)

          const commitSnap = await transaction.get(docRef)

          if (commitSnap.exists()) {
            commitSnaps.push(commitSnap)
          }
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number }[] = []

        for (const commitSnap of commitSnaps) {
          const commitData = commitSnap.data() as CommitToolFirebaseType

          for (const tool of commitData.tools) {
            const stockID = this.getStockId(commitData.warehouse.id, tool.ref.id)

            const stockRef = doc(firestore, Collections.STOCKS_TOOLS.NAME, stockID)

            const stockSnap = await transaction.get(stockRef)

            if (stockSnap.exists()) {
              stockSnaps.push({ snap: stockSnap, quantity: tool.quantity })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity } = item

          const stockData = snap.data() as StockToolBaseType

          transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
        }

        for (const commitSnap of commitSnaps) {
          transaction.delete(commitSnap.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMITS)
    }
  }
}

export default WarehouseEntitiesImpl
