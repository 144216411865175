import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { sha256 } from 'crypto-hash'
import { type FirebaseError } from 'firebase/app'

import {
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth'

import { doc, query, setDoc, getDocs, orderBy, updateDoc, collection } from 'firebase/firestore'

import type { SignInType, SignUpType, UserType } from '@customTypes/user'

import { Logger } from '@utils/log'
import { ErrorCodes, ErrorService } from '@utils/error'
import { Collections, GEOAPIFY_URL } from '@constants/api'

import {
  CreateGeoCheckpointType,
  GeoAPIfyType,
  GeoCheckpointBaseType,
  GeoCheckpointFirebaseType,
  GeoPropertiesType,
} from '@customTypes/geoCheckpoint'

import UserDS from '@api/domain/ds/UserDS'

import {
  auth,
  firestore,
  parseEntityToRef,
  parseRefToEntity,
  uploadAttachment,
} from '@utils/firebase'

const SkipEmails = ['jverduga@seinproec.com', 'enmanuelmag@gmail.com']

const DEVICE_ID_KEY = 'device-id-seinpro'

const USER_ROLE_KEY = 'user-role-seinpro'

class UserImpl extends UserDS {
  static instance: UserImpl

  private constructor() {
    super()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new UserImpl()
    }

    return this.instance
  }

  async getDeviceID() {
    try {
      const { userAgent, hardwareConcurrency } = window.navigator
      const { height, width, pixelDepth, colorDepth } = window.screen

      const info = `${userAgent}-${hardwareConcurrency}-${height}-${width}-${pixelDepth}-${colorDepth}`

      return await sha256(info)
    } catch (error) {
      Logger.error('Error getting device id', error)
      return 'dummy-device-id'
    }
  }

  async checkDeviceID(user: UserType) {
    if (user.deviceID && user.deviceID !== 'dummy-device-id') {
      return user
    }

    const deviceID = await this.getDeviceID()

    const userRef = doc(firestore, Collections.USERS.NAME, user.uid)

    const newUserData: UserType = {
      ...user,
      deviceID,
    }

    await updateDoc(userRef, {
      deviceID,
    })

    return newUserData
  }

  async signinWithEmailAndPassword(params: SignInType) {
    try {
      const { email, password } = params
      const { user: userSDK } = await signInWithEmailAndPassword(
        auth,
        email.toLowerCase().trim(),
        password,
      )

      const userRef = doc(firestore, Collections.USERS.NAME, userSDK.uid)

      const userTemp = await parseRefToEntity<UserType>(userRef)

      const userData = await this.checkDeviceID(userTemp)

      const user: UserType = {
        uid: userSDK.uid,
        role: userData.role,
        email: userSDK.email || '',
        deviceID: userData.deviceID,
        displayName: userSDK.displayName || '',
      }

      localStorage.setItem(DEVICE_ID_KEY, user.deviceID)

      localStorage.setItem(USER_ROLE_KEY, user.role)

      return user
    } catch (error) {
      Logger.error('Error login with email and password', error)

      const errorFirebase = error as FirebaseError
      if (errorFirebase.code === 'auth/user-not-found') {
        throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
      } else if (errorFirebase.code === 'auth/invalid-credential') {
        throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
      } else {
        throw ErrorService.get(ErrorCodes.ERROR_LOGIN_WITH_EMAIL_AND_PASSWORD)
      }
    }
  }

  async signUpWithEmailAndPassword(params: SignUpType) {
    try {
      const { email, password, displayName } = params

      const { user: userSDK } = await createUserWithEmailAndPassword(
        auth,
        email.toLowerCase().trim(),
        password,
      )

      updateProfile(userSDK, {
        displayName,
      })

      const deviceID = await this.getDeviceID()

      const user: UserType = {
        uid: userSDK.uid,
        deviceID,
        displayName,
        role: 'user',
        email: userSDK.email || '',
      }

      //save user on database
      const userRef = doc(firestore, Collections.USERS.NAME, user.uid)

      localStorage.setItem(DEVICE_ID_KEY, deviceID)

      localStorage.setItem(USER_ROLE_KEY, user.role)

      await setDoc(userRef, user)

      return user
    } catch (error) {
      Logger.error('Error register with email and password', error)

      throw ErrorService.get(ErrorCodes.ERROR_REGISTER_WITH_EMAIL_AND_PASSWORD)
    }
  }

  async getUsers() {
    try {
      const collectionRef = collection(firestore, Collections.USERS.NAME)

      const usersSnap = await getDocs(collectionRef)

      const usersData = usersSnap.docs.map((doc) => doc.data()) as UserType[]

      return usersData
    } catch (error) {
      Logger.error('Error getting users', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_USERS)
    }
  }

  async getUser() {
    try {
      const firebaseUser = auth.currentUser

      const deviceID = localStorage.getItem(DEVICE_ID_KEY) ?? ''

      const role = localStorage.getItem(USER_ROLE_KEY) as 'admin' | 'user'

      if (firebaseUser) {
        const user: UserType = {
          deviceID,
          displayName: firebaseUser.displayName || '',
          email: firebaseUser.email || '',
          role,
          uid: firebaseUser.uid,
        }

        return user
      }

      const currentUser = await new Promise<UserType | null>((resolve, reject) =>
        onAuthStateChanged(
          auth,
          (user) => {
            if (!user) return resolve(null)

            return resolve({
              uid: user.uid,
              email: user.email || '',
              displayName: user.displayName || '',
              deviceID,
              role,
            } as UserType)
          },
          (error) => reject(error),
        ),
      )

      return currentUser as UserType
    } catch (error) {
      Logger.error('Error getting user from Firebase', error)
      throw ErrorService.get(ErrorCodes.ERROR_SESSION_EXPIRED)
    }
  }

  async logout() {
    try {
      await auth.signOut()
    } catch (error) {
      Logger.error('Error logout', error)
      throw ErrorService.get(ErrorCodes.ERROR_LOGOUT)
    }
  }

  async deleteAccount() {
    try {
      const user = auth.currentUser

      if (!user) {
        Logger.error('User not found')
        throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
      }

      await user.delete()
    } catch (error) {
      Logger.error('Error deleting account', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_ACCOUNT)
    }
  }

  async saveUserGeoCheckpoint(params: CreateGeoCheckpointType) {
    if (!navigator.geolocation) {
      throw ErrorService.get(ErrorCodes.ERROR_GEOLOCATION_NOT_AVAILABLE)
    }
    const user = await this.getUser()

    if (!user) {
      throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
    }

    try {
      const { attachment, message } = params

      const userFirebase = await parseRefToEntity<UserType>(
        doc(firestore, Collections.USERS.NAME, user.uid),
      )

      if (user.deviceID !== userFirebase.deviceID && !SkipEmails.includes(user.email)) {
        throw ErrorService.get(ErrorCodes.ERROR_DEVICE_ID_CHANGED)
      }

      const position = await new Promise<GeolocationPosition>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject),
      )

      const geoURL = new URL(GEOAPIFY_URL)

      geoURL.searchParams.append('lat', position.coords.latitude.toString())
      geoURL.searchParams.append('lon', position.coords.longitude.toString())
      geoURL.searchParams.append('apiKey', import.meta.env.VITE_GEOAPIFY_KEY)

      const result = await fetch(geoURL.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })

      const geoData = (await result.json()) as GeoAPIfyType

      let properties: GeoPropertiesType | null = null

      if (geoData.features.length) {
        const [feature] = geoData.features
        const { name, country, state, city, street, suburb } = feature.properties

        properties = {
          name,
          country,
          state,
          city,
          street,
          suburb,
        }
      }

      const currentTimestamp = moment().unix()

      const attachmentPath = await uploadAttachment({
        entity: 'geoCheckpoint',
        entityId: user.uid,
        base64: attachment,
      })

      const geoCheckpoint: GeoCheckpointFirebaseType = {
        uid: uuidv4(),
        user: parseEntityToRef<UserType>(user),
        message,
        deviceID: user.deviceID,
        attachment: attachmentPath,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
        location: {
          accuracy: position.coords.accuracy,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed || -1,
          altitude: position.coords.altitude || -1,
          properties,
        },
      }

      const geoCheckpointRef = doc(firestore, Collections.GEO_CHECKPOINTS.NAME, geoCheckpoint.uid)

      await setDoc(geoCheckpointRef, geoCheckpoint)
    } catch (error) {
      Logger.error('Error saving user geo checkpoint', error)
      throw error
    }
  }

  async getGeoCheckpoints() {
    try {
      const collectionRef = collection(firestore, Collections.GEO_CHECKPOINTS.NAME)

      const q = query(collectionRef, orderBy('createdAt', 'desc'))

      const geoCheckpointsSnap = await getDocs(q)

      const geoCheckpointsFirebaseData = geoCheckpointsSnap.docs.map((doc) =>
        doc.data(),
      ) as GeoCheckpointFirebaseType[]

      const geoCheckpointsData: GeoCheckpointBaseType[] = []

      for (const geoCheckpoint of geoCheckpointsFirebaseData) {
        const user = await parseRefToEntity<UserType>(geoCheckpoint.user)

        const geoCheckpointData: GeoCheckpointBaseType = {
          uid: geoCheckpoint.uid,
          user: {
            uid: user.uid,
            displayName: user.displayName,
            deviceID: user.deviceID,
            email: user.email,
            role: user.role,
          },
          deviceID: user.deviceID,
          message: geoCheckpoint.message,
          attachment: geoCheckpoint.attachment,
          location: geoCheckpoint.location,
          createdAt: geoCheckpoint.createdAt,
          updatedAt: geoCheckpoint.updatedAt,
        }

        geoCheckpointsData.push(geoCheckpointData)
      }

      return geoCheckpointsData
    } catch (error) {
      Logger.error('Error getting geo checkpoints', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_GEO_CHECKPOINTS)
    }
  }

  async checkBiometric() {
    try {
      const user = auth.currentUser

      if (!user) {
        Logger.error('User not found')
        throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
      }

      return true
    } catch (error) {
      Logger.error('Error checking biometric', error)
      throw error
      //throw ErrorService.get(ErrorCodes.ERROR_CHECKING_BIOMETRIC)
    }
  }
}

export default UserImpl
