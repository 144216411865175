import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { type FirebaseApp, initializeApp } from 'firebase/app'

import {
  type Firestore,
  doc,
  limit,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  orderBy,
  updateDoc,
  deleteDoc,
  collection,
  writeBatch,
  startAfter,
  getFirestore,
  runTransaction,
  QueryConstraint,
  initializeFirestore,
  DocumentSnapshot,
} from 'firebase/firestore'

import { Logger } from '@utils/log'
import { ErrorCodes, ErrorService } from '@utils/error'
import { Collections } from '@constants/api'

import {
  CreateClientType,
  GetClientsType,
  ClientBaseType,
  ClientFirebaseType,
  UpdateClientType,
  ClientType,
} from '@customTypes/client'

import {
  CreateEventType,
  DeleteEventsType,
  EventBaseType,
  EventFirebaseType,
  GetEventsType,
  UpdateEventType,
} from '@customTypes/event'

import ClientEntityDS from '@api/domain/ds/ClientEntityDS'
import { parseRefToEntity } from '@utils/firebase'

const ConfigCredentials = {
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  },
}

class ClientEntityImpl extends ClientEntityDS {
  db: Firestore
  private app: FirebaseApp

  static instance: ClientEntityImpl

  constructor() {
    super()
    this.app = initializeApp(ConfigCredentials.firebaseConfig)
    this.db = this.buildFirestore()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ClientEntityImpl()
    }

    return this.instance
  }

  buildFirestore() {
    try {
      return initializeFirestore(this.app, {
        ignoreUndefinedProperties: true,
        experimentalForceLongPolling: false,
      })
    } catch (error) {
      return getFirestore(this.app)
    }
  }

  //Clients
  paseToEventBase(eventFirebase: EventFirebaseType) {
    const { uid, attachments, date, description, name, amount, deleted, createdAt, updatedAt } =
      eventFirebase

    const eventBase: EventBaseType = {
      uid,
      description,
      name,
      amount,
      attachments,
      date,
      deleted,
      createdAt,
      updatedAt,
    }

    return eventBase
  }

  parseToClientBase(opportunityFirebase: ClientFirebaseType) {
    const {
      uid,
      name,
      ruc,
      email,
      description,
      location,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      phone,
    } = opportunityFirebase

    const opportunityBase: ClientBaseType = {
      uid,
      name,
      ruc,
      email,
      location,
      description,
      remainder,
      contacts,
      priority,
      phone,
      updatedAt,
      createdAt,
    }

    return opportunityBase
  }

  async parseToClient(opportunityFirebase: ClientFirebaseType) {
    const {
      uid,
      name,
      ruc,
      email,
      description,
      location,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      events,
    } = opportunityFirebase

    const eventsData: EventBaseType[] = []

    for (const eventRef of events) {
      const eventFirebaseData = await parseRefToEntity<EventFirebaseType>(eventRef)

      const eventData = this.paseToEventBase(eventFirebaseData)

      eventsData.push(eventData)
    }

    const opportunityData: ClientType = {
      uid,
      name,
      ruc,
      email,
      location,
      description,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      events: eventsData,
    }

    return opportunityData
  }

  async getClients(params: GetClientsType) {
    try {
      const collectionRef = collection(this.db, Collections.CLIENTS.NAME)

      const queries: QueryConstraint[] = []

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const opportunitiesSnap = await getDocs(q)

      const opportunitiesData = opportunitiesSnap.docs.map((doc) =>
        doc.data(),
      ) as ClientFirebaseType[]

      const opportunitiesBase: ClientBaseType[] = []

      for (const client of opportunitiesData) {
        opportunitiesBase.push(this.parseToClientBase(client))
      }

      return opportunitiesBase
    } catch (error) {
      Logger.error('Error getting opportunities', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_CLIENTS)
    }
  }

  async getClientById(id: string) {
    try {
      const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

      const opportunitySnap = await getDoc(docRef)

      const opportunityFirebaseData = opportunitySnap.data() as ClientFirebaseType

      return this.parseToClientBase(opportunityFirebaseData)
    } catch (error) {
      Logger.error('Error getting client by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_CLIENTS)
    }
  }

  async createClient(data: CreateClientType) {
    try {
      const currentTimestamp = moment().unix()

      const newClient: ClientFirebaseType = {
        ...data,
        uid: uuidv4(),
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
        events: [],
      }

      await setDoc(doc(this.db, Collections.CLIENTS.NAME, newClient.uid), newClient)

      const clientData: ClientBaseType = {
        uid: newClient.uid,
        name: newClient.name,
        ruc: newClient.ruc,
        email: newClient.email,
        location: newClient.location,
        description: newClient.description,
        remainder: newClient.remainder,
        contacts: newClient.contacts,
        priority: newClient.priority,
        updatedAt: newClient.updatedAt,
        createdAt: newClient.createdAt,
      }

      return clientData
    } catch (error) {
      Logger.error('Error creating client', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_CLIENT)
    }
  }

  async updateClient(data: UpdateClientType) {
    try {
      const updateClient: Partial<ClientFirebaseType> = {}

      if (data.name) {
        updateClient.name = data.name
      }

      if (data.ruc) {
        updateClient.ruc = data.ruc
      }

      if (data.email) {
        updateClient.email = data.email
      }

      if (data.location) {
        updateClient.location = data.location
      }

      if (data.remainder) {
        updateClient.remainder = data.remainder
      }

      if (data.contacts) {
        updateClient.contacts = data.contacts
      }

      if (data.priority) {
        updateClient.priority = data.priority
      }

      if (Object.keys(updateClient).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      const docRef = doc(this.db, Collections.CLIENTS.NAME, data.uid)

      await updateDoc(docRef, updateClient)
    } catch (error) {
      Logger.error('Error updating client', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_CLIENT)
    }
  }

  async deleteClient(id: string) {
    try {
      const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting client', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_CLIENT)
    }
  }

  async deleteClients(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      ids.forEach((id) => {
        const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting opportunities', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_CLIENTS)
    }
  }

  //Events
  async getEvents(params: GetEventsType) {
    try {
      const collectionRef = collection(this.db, Collections.EVENTS.NAME)

      const queries: QueryConstraint[] = [
        where('client', '==', doc(this.db, Collections.CLIENTS.NAME, params.clientID)),
      ]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const eventsSnap = await getDocs(q)

      const eventsData = eventsSnap.docs.map((doc) => doc.data()) as EventFirebaseType[]

      const eventsBase: EventBaseType[] = []

      for (const event of eventsData) {
        eventsBase.push(this.paseToEventBase(event))
      }

      return eventsBase
    } catch (error) {
      Logger.error('Error getting events', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_EVENTS)
    }
  }

  async createEvent(params: CreateEventType) {
    try {
      const newEventBase: EventBaseType = await runTransaction(this.db, async (transaction) => {
        const { clientID, data } = params

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        const clientSnap = await transaction.get(clientRef)

        const clientData = clientSnap.data() as ClientFirebaseType

        const currentTimestamp = moment().unix()

        const newEvent: EventFirebaseType = {
          ...data,
          uid: uuidv4(),
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          client: doc(this.db, Collections.CLIENTS.NAME, clientID),
        }

        transaction.set(doc(this.db, Collections.EVENTS.NAME, newEvent.uid), newEvent)

        const events = clientData.events ?? []

        events.push(doc(this.db, Collections.EVENTS.NAME, newEvent.uid))

        transaction.update(clientRef, { events })

        return this.paseToEventBase(newEvent)
      })

      return newEventBase
    } catch (error) {
      Logger.error('Error creating event', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_EVENT)
    }
  }

  async updateEvent(params: UpdateEventType) {
    try {
      const { clientID, data } = params

      await runTransaction(this.db, async (transaction) => {
        const docRef = doc(this.db, Collections.EVENTS.NAME, data.uid)

        const updateEvent: Partial<EventFirebaseType> = {}

        if (data.name) {
          updateEvent.name = data.name
        }

        if (data.description) {
          updateEvent.description = data.description
        }

        if (data.amount) {
          updateEvent.amount = data.amount
        }

        if (data.attachments) {
          updateEvent.attachments = data.attachments
        }

        if (data.date) {
          updateEvent.date = data.date
        }

        if (Object.keys(updateEvent).length === 0) {
          throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
        }

        transaction.update(docRef, updateEvent)

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        transaction.update(clientRef, { updatedAt: moment().unix() })
      })
    } catch (error) {
      Logger.error('Error updating event', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_EVENT)
    }
  }

  async deleteEvent(id: string) {
    try {
      await runTransaction(this.db, async (transaction) => {
        const docRef = doc(this.db, Collections.EVENTS.NAME, id)

        const eventSnap = await transaction.get(docRef)

        const event = eventSnap.data() as EventFirebaseType

        const clientSnap = await transaction.get(event.client)

        const clientData = clientSnap.data() as ClientFirebaseType

        const events = clientData.events ?? []

        const index = events.findIndex((e) => e.id === id)

        if (index !== -1) {
          events.splice(index, 1)
        }

        transaction.update(event.client, { events })

        transaction.update(docRef, { deleted: true, updatedAt: moment().unix() })
      })
    } catch (error) {
      Logger.error('Error deleting event', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_EVENT)
    }
  }

  async deleteEvents(parmas: DeleteEventsType) {
    try {
      const { clientID, uids } = parmas
      await runTransaction(this.db, async (transaction) => {
        const eventSnaps: DocumentSnapshot[] = []

        for (const id of uids) {
          const docRef = doc(this.db, Collections.EVENTS.NAME, id)

          const eventSnap = await transaction.get(docRef)

          eventSnaps.push(eventSnap)
        }

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        const clientSnap = await transaction.get(clientRef)

        const clientData = clientSnap.data() as ClientFirebaseType

        const events = clientData.events ?? []

        const eventsFiltered = events.filter((e) => !uids.includes(e.id))

        transaction.update(clientRef, { events: eventsFiltered })

        for (const eventSnap of eventSnaps) {
          transaction.delete(eventSnap.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting events', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_EVENTS)
    }
  }
}

export default ClientEntityImpl
