const QueryKeys = {
  LOGIN_KEY: 'login',
  GET_USER_KEY: 'get-user',
  CREATE_GEO_CHECKPOINT_KEY: 'create-geo-checkpoint',
  GET_GEO_CHECKPOINTS_KEY: 'get-geo-checkpoints',
  GET_GEO_CHECKPOINT_KEY: 'get-geo-checkpoint',
  //commits
  GET_COMMIT_KEY: 'get-commit',
  GET_COMMITS_KEY: 'get-commits',
  DELETE_COMMIT_KEY: 'delete-commit',
  //commitsTool
  GET_COMMIT_TOOL_KEY: 'get-commit-tool',
  GET_COMMIT_TOOLS_KEY: 'get-commit-tools',
  DELETE_COMMIT_TOOL_KEY: 'delete-commit-tool',
  //materials
  GET_MATERIALS_KEY: 'get-materials',
  GET_RAW_MATERIAL_KEY: 'get-raw-material',
  GET_MATERIALS_ATTACHMENTS_KEY: 'get-materials-attachments',
  //tools
  GET_TOOL_KEY: 'get-tool',
  GET_TOOLS_KEY: 'get-tools',
  GET_TOOLS_ATTACHMENTS_KEY: 'get-tools-attachments',
  //warehouses
  GET_WAREHOUSE_KEY: 'get-warehouse',
  GET_WAREHOUSES_KEY: 'get-warehouses',
  //warehousesTool
  GET_WAREHOUSE_TOOL_KEY: 'get-warehouse-tool',
  GET_WAREHOUSE_TOOLS_KEY: 'get-warehouse-tools',
  //receipts
  GET_RECEIPT_KEY: 'get-receipt',
  GET_RECEIPTS_KEY: 'get-receipts',
  GET_RECEIPTS_EXTENDED_KEY: 'get-receipts-extended',
  //blocks
  GET_BLOCK_KEY: 'get-block',
  GET_BLOCKS_KEY: 'get-blocks',
  GET_BLOCKS_EXTENDED_KEY: 'get-blocks-extended',
  //opportunities
  GET_CLIENT_KEY: 'get-client',
  GET_CLIENTS_KEY: 'get-opportunities',
  GET_EVENTS_KEY: 'get-events',
  GET_EVENT_KEY: 'get-event',
  //proformas
  GET_PROFORMA_KEY: 'get-proforma',
  GET_PROFORMAS_KEY: 'get-proformas',
  GET_PROFORMA_TOTAL_KEY: 'get-proforma-total',
  GET_SOURCE_DATA_KEY: 'get-source-data',
}

export default QueryKeys
