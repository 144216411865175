const UserErrors = {
  //Get user
  ERROR_GETTING_USER: {
    code: 'ERROR_GETTING_USER',
    message: 'Error al obtener el usuario',
  },
  ERROR_GETTING_ACCESS: {
    code: 'ERROR_GETTING_ACCESS',
    message: 'Error al obtener el acceso',
  },
  //Login
  ERROR_LOGIN: {
    code: 'ERROR_LOGIN',
    message: 'Error al iniciar sesión',
  },
  //Logout
  ERROR_LOGOUT: {
    code: 'ERROR_LOGOUT',
    message: 'Error al cerrar sesión',
  },
  //Register
  ERROR_REGISTER: {
    code: 'ERROR_REGISTER',
    message: 'Error al registrar',
  },
  //Unknown
  UNKNOWN: {
    code: 'UNKNOWN',
    message: 'Error desconocido',
  },
  INVALID_PARAMS: {
    code: 'INVALID_PARAMS',
    message: 'Parámetros inválidos',
  },
  //User
  ERROR_USER_NOT_FOUND: {
    code: 'ERROR_USER_NOT_FOUND',
    message: 'Usuario no encontrado',
  },
  ERROR_GETTING_USERS: {
    code: 'ERROR_GETTING_USERS',
    message: 'Error al obtener usuarios',
  },
  ERROR_LOGIN_WITH_EMAIL_AND_PASSWORD: {
    code: 'ERROR_LOGIN_WITH_EMAIL_AND_PASSWORD',
    message: 'Error al iniciar sesión con correo y contraseña',
  },
  ERROR_REGISTER_WITH_EMAIL_AND_PASSWORD: {
    code: 'ERROR_REGISTER_WITH_EMAIL_AND_PASSWORD',
    message: 'Error al registrar con correo y contraseña',
  },
  ERROR_SESSION_EXPIRED: {
    code: 'ERROR_SESSION_EXPIRED',
    message: 'Sesión expirada',
  },
  ERROR_DELETING_ACCOUNT: {
    code: 'ERROR_DELETING_ACCOUNT',
    message: 'Error al eliminar cuenta',
  },
  ERROR_GEOLOCATION_NOT_AVAILABLE: {
    code: 'ERROR_GEOLOCATION_NOT_AVAILABLE',
    message: 'Geolocalización no disponible',
  },
  ERROR_GETTING_GEOLLOCATION: {
    code: 'ERROR_GETTING_GEOLLOCATION',
    message: 'Error al obtener geolocalización',
  },
  ERROR_DEVICE_ID_CHANGED: {
    code: 'ERROR_DEVICE_ID_CHANGED',
    message: 'El usuario no está usando el mismo dispositivo',
  },
  ERROR_GETTING_GEO_CHECKPOINTS: {
    code: 'ERROR_GETTING_GEO_CHECKPOINTS',
    message: 'Error al obtener geolocalizaciones',
  },
}

const RawMaterialErrors = {
  ERROR_DOWNLOADING_ATTACHMENT: {
    code: 'ERROR_DOWNLOADING_ATTACHMENT',
    message: 'Error al descargar archivo',
  },
  ERROR_GETTING_STOCK: {
    code: 'ERROR_GETTING_STOCK',
    message: 'Error al obtener stock',
  },
  ERROR_UPLOADING_ATTACHMENT: {
    code: 'ERROR_UPLOADING_ATTACHMENT',
    message: 'Error al subir archivo',
  },
  ERROR_DELETING_ATTACHMENTS: {
    code: 'ERROR_DELETING_ATTACHMENTS',
    message: 'Error al eliminar archivos adjuntos',
  },
  ERROR_GETTING_RAW_MATERIALS: {
    code: 'ERROR_GETTING_RAW_MATERIALS',
    message: 'Error al obtener materias primas',
  },
  ERROR_CREATING_RAW_MATERIAL: {
    code: 'ERROR_CREATING_RAW_MATERIAL',
    message: 'Error al crear materia prima',
  },
  ERROR_DELETING_RAW_MATERIAL: {
    code: 'ERROR_DELETING_RAW_MATERIAL',
    message: 'Error al eliminar materia prima',
  },
  ERROR_UPDATING_RAW_MATERIAL: {
    code: 'ERROR_UPDATING_RAW_MATERIAL',
    message: 'Error al actualizar materia prima',
  },
  ERROR_GETTING_ID: {
    code: 'ERROR_GETTING_ID',
    message: 'Error al obtener ID',
  },
  ERROR_DELETING_RAW_MATERIALS: {
    code: 'ERROR_DELETING_RAW_MATERIALS',
    message: 'Error al eliminar materias primas',
  },
}

const ToolErrors = {
  ERROR_GETTING_TOOLS: {
    code: 'ERROR_GETTING_TOOLS',
    message: 'Error al obtener herramientas',
  },
  ERROR_CREATING_TOOL: {
    code: 'ERROR_CREATING_TOOL',
    message: 'Error al crear herramienta',
  },
  ERROR_DELETING_TOOL: {
    code: 'ERROR_DELETING_TOOL',
    message: 'Error al eliminar herramienta',
  },
  ERROR_UPDATING_TOOL: {
    code: 'ERROR_UPDATING_TOOL',
    message: 'Error al actualizar herramienta',
  },
  ERROR_DELETING_TOOLS: {
    code: 'ERROR_DELETING_TOOLS',
    message: 'Error al eliminar herramientas',
  },
  ERROR_DELETING_ATTACHMENTS_FROM_TOOL: {
    code: 'ERROR_DELETING_ATTACHMENTS_FROM_TOOL',
    message: 'Error al eliminar archivos adjuntos de herramienta',
  },
}

const WarehouseErrors = {
  ERROR_GETTING_WAREHOUSES: {
    code: 'ERROR_GETTING_WAREHOUSES',
    message: 'Error al obtener almacenes',
  },
  ERROR_CREATING_WAREHOUSE: {
    code: 'ERROR_CREATING_WAREHOUSE',
    message: 'Error al crear almacén',
  },
  ERROR_DELETING_WAREHOUSE: {
    code: 'ERROR_DELETING_WAREHOUSE',
    message: 'Error al eliminar almacén',
  },
  ERROR_UPDATING_WAREHOUSE: {
    code: 'ERROR_UPDATING_WAREHOUSE',
    message: 'Error al actualizar almacén',
  },
  ERROR_DELETING_WAREHOUSES: {
    code: 'ERROR_DELETING_WAREHOUSES',
    message: 'Error al eliminar almacenes',
  },
  ERROR_MOVING_RAW_MATERIAL: {
    code: 'ERROR_MOVING_RAW_MATERIAL',
    message: 'Error al mover materia prima',
  },
  ERROR_GETTING_MATERIALS_BY_WAREHOUSE: {
    code: 'ERROR_GETTING_MATERIALS_BY_WAREHOUSE',
    message: 'Error al obtener materiales por almacén',
  },
  ERROR_NOT_ENOUGH_STOCK: {
    code: 'ERROR_NOT_ENOUGH_STOCK',
    message: 'No hay suficiente stock',
  },
}

const ToolWarehouseErrors = {
  ERROR_GETTING_TOOLS_BY_WAREHOUSE_TOOL: {
    code: 'ERROR_GETTING_TOOLS_BY_WAREHOUSE_TOOL',
    message: 'Error al obtener herramientas por almacén',
  },
  ERROR_CREATING_TOOL_IN_WAREHOUSE: {
    code: 'ERROR_CREATING_TOOL_IN_WAREHOUSE',
    message: 'Error al crear herramienta en almacén',
  },
  ERROR_DELETING_TOOL_FROM_WAREHOUSE: {
    code: 'ERROR_DELETING_TOOL_FROM_WAREHOUSE',
    message: 'Error al eliminar herramienta de almacén',
  },
  ERROR_UPDATING_TOOL_IN_WAREHOUSE: {
    code: 'ERROR_UPDATING_TOOL_IN_WAREHOUSE',
    message: 'Error al actualizar herramienta en almacén',
  },
  ERROR_DELETING_TOOLS_FROM_WAREHOUSE: {
    code: 'ERROR_DELETING_TOOLS_FROM_WAREHOUSE',
    message: 'Error al eliminar herramientas de almacén',
  },
  ERROR_GETTING_TOOL_BY_ID: {
    code: 'ERROR_GETTING_TOOL_BY_ID',
    message: 'Error al obtener herramienta por ID',
  },
  ERROR_GETTING_TOOL_BY_ID_AND_WAREHOUSE: {
    code: 'ERROR_GETTING_TOOL_BY_ID_AND_WAREHOUSE',
    message: 'Error al obtener herramienta por ID y almacén',
  },
  ERROR_GETTING_TOOLS_BY_WAREHOUSE: {
    code: 'ERROR_GETTING_TOOLS_BY_WAREHOUSE',
    message: 'Error al obtener herramientas por almacén',
  },
  ERROR_GETTING_TOOL_BY_WAREHOUSE: {
    code: 'ERROR_GETTING_TOOL_BY_WAREHOUSE',
    message: 'Error al obtener herramienta por almacén',
  },
  ERROR_CREATING_WAREHOUSE_TOOL: {
    code: 'ERROR_CREATING_WAREHOUSE_TOOL',
    message: 'Error al crear herramienta de almacén',
  },
  ERROR_UPDATING_WAREHOUSE_TOOL: {
    code: 'ERROR_UPDATING_WAREHOUSE_TOOL',
    message: 'Error al actualizar herramienta de almacén',
  },
  ERROR_DELETING_WAREHOUSE_TOOL: {
    code: 'ERROR_DELETING_WAREHOUSE_TOOL',
    message: 'Error al eliminar herramienta de almacén',
  },
  ERROR_DELETING_WAREHOUSE_TOOLS: {
    code: 'ERROR_DELETING_WAREHOUSE_TOOLS',
    message: 'Error al eliminar herramienta de almacén',
  },
  ERROR_MOVING_TOOLS_TO_WAREHOUSE: {
    code: 'ERROR_MOVING_TOOLS_TO_WAREHOUSE',
    message: 'Error al mover herramientas a almacén',
  },
  ERROR_DELETING_TOOLS_FROM_WAREHOUSE_TOOL: {
    code: 'ERROR_DELETING_TOOLS_FROM_WAREHOUSE_TOOL',
    message: 'Error al eliminar herramientas de almacén',
  },
}

const ReceiptErrors = {
  ERROR_GETTING_RECEIPTS: {
    code: 'ERROR_GETTING_RECEIPTS',
    message: 'Error al obtener recetas',
  },
  ERROR_CREATING_RECEIPT: {
    code: 'ERROR_CREATING_RECEIPT',
    message: 'Error al crear receta',
  },
  ERROR_DELETING_RECEIPT: {
    code: 'ERROR_DELETING_RECEIPT',
    message: 'Error al eliminar receta',
  },
  ERROR_UPDATING_RECEIPT: {
    code: 'ERROR_UPDATING_RECEIPT',
    message: 'Error al actualizar receta',
  },
  ERROR_NOT_FIELDS_TO_UPDATE: {
    code: 'ERROR_NOT_FIELDS_TO_UPDATE',
    message: 'No hay campos para actualizar',
  },
  ERROR_DELETING_RECEIPTS: {
    code: 'ERROR_DELETING_RECEIPTS',
    message: 'Error al eliminar recetas',
  },
  ERROR_DELETING_RAW_MATERIALS_FROM_WAREHOUSE: {
    code: 'ERROR_DELETING_RAW_MATERIALS_FROM_WAREHOUSE',
    message: 'Error al eliminar materias primas de almacén',
  },
  ERROR_DELETING_RAW_MATERIALS_FROM_RECEIPT: {
    code: 'ERROR_DELETING_RAW_MATERIALS_FROM_RECEIPT',
    message: 'Error al eliminar materias primas de receta',
  },
}

const CommitErrors = {
  ERROR_GETTING_COMMITS: {
    code: 'ERROR_GETTING_COMMITS',
    message: 'Error al obtener commits',
  },
  ERROR_CREATING_COMMIT: {
    code: 'ERROR_CREATING_COMMIT',
    message: 'Error al crear commit',
  },
  ERROR_DELETING_COMMIT: {
    code: 'ERROR_DELETING_COMMIT',
    message: 'Error al eliminar commit',
  },
  ERROR_UPDATING_COMMIT: {
    code: 'ERROR_UPDATING_COMMIT',
    message: 'Error al actualizar commit',
  },
  ERROR_DELETING_COMMITS: {
    code: 'ERROR_DELETING_COMMITS',
    message: 'Error al eliminar commits',
  },
  ERROR_NOT_RAW_MATERIAL_COMMIT: {
    code: 'ERROR_NOT_RAW_MATERIAL_COMMIT',
    message: 'Materia prima no encontrada para crear registro',
  },
}

const BlockErrors = {
  ERROR_GETTING_BLOCKS: {
    code: 'ERROR_GETTING_BLOCKS',
    message: 'Error al obtener bloques',
  },
  ERROR_CREATING_BLOCK: {
    code: 'ERROR_CREATING_BLOCK',
    message: 'Error al crear bloque',
  },
  ERROR_DELETING_BLOCK: {
    code: 'ERROR_DELETING_BLOCK',
    message: 'Error al eliminar bloque',
  },
  ERROR_UPDATING_BLOCK: {
    code: 'ERROR_UPDATING_BLOCK',
    message: 'Error al actualizar bloque',
  },
  ERROR_DELETING_BLOCKS: {
    code: 'ERROR_DELETING_BLOCKS',
    message: 'Error al eliminar bloques',
  },
  ERROR_DELETING_RECEIPTS_FROM_BLOCK: {
    code: 'ERROR_DELETING_RECEIPTS_FROM_BLOCK',
    message: 'Error al eliminar recetas de bloque',
  },
}

const ClientErrors = {
  ERROR_GETTING_CLIENTS: {
    code: 'ERROR_GETTING_CLIENTS',
    message: 'Error al obtener oportunidades',
  },
  ERROR_GETTING_CLIENT_BY_ID: {
    code: 'ERROR_GETTING_CLIENT_BY_ID',
    message: 'Error al obtener oportunidad por ID',
  },
  ERROR_CREATING_CLIENT: {
    code: 'ERROR_CREATING_CLIENT',
    message: 'Error al crear oportunidad',
  },
  ERROR_DELETING_CLIENT: {
    code: 'ERROR_DELETING_CLIENT',
    message: 'Error al eliminar oportunidad',
  },
  ERROR_UPDATING_CLIENT: {
    code: 'ERROR_UPDATING_CLIENT',
    message: 'Error al actualizar oportunidad',
  },
  ERROR_DELETING_CLIENTS: {
    code: 'ERROR_DELETING_CLIENTS',
    message: 'Error al eliminar oportunidades',
  },
}

const ProformaErrors = {
  ERROR_GETTING_PROFORMAS: {
    code: 'ERROR_GETTING_PROFORMAS',
    message: 'Error al obtener proformas',
  },
  ERROR_GETTING_PROFORMA_BY_ID: {
    code: 'ERROR_GETTING_PROFORMA_BY_ID',
    message: 'Error al obtener proforma por ID',
  },
  ERROR_CREATING_PROFORMA: {
    code: 'ERROR_CREATING_PROFORMA',
    message: 'Error al crear proforma',
  },
  ERROR_DELETING_PROFORMA: {
    code: 'ERROR_DELETING_PROFORMA',
    message: 'Error al eliminar proforma',
  },
  ERROR_UPDATING_PROFORMA: {
    code: 'ERROR_UPDATING_PROFORMA',
    message: 'Error al actualizar proforma',
  },
  ERROR_DELETING_PROFORMAS: {
    code: 'ERROR_DELETING_PROFORMAS',
    message: 'Error al eliminar proformas',
  },
  ERROR_GETTING_ID_PROFORMA: {
    code: 'ERROR_GETTING_ID_PROFORMA',
    message: 'Error al obtener ID de proforma',
  },
}

const EventsErrors = {
  ERROR_CREATING_EVENT: {
    code: 'ERROR_CREATING_EVENT',
    message: 'Error al crear evento',
  },
  ERROR_GETTING_EVENTS: {
    code: 'ERROR_GETTING_EVENTS',
    message: 'Error al obtener eventos',
  },
  ERROR_UPDATING_EVENT: {
    code: 'ERROR_UPDATING_EVENT',
    message: 'Error al actualizar evento',
  },
  ERROR_DELETING_EVENT: {
    code: 'ERROR_DELETING_EVENT',
    message: 'Error al eliminar evento',
  },
  ERROR_DELETING_EVENTS: {
    code: 'ERROR_DELETING_EVENTS',
    message: 'Error al eliminar eventos',
  },
}

const ExportErrors = {
  ERROR_EXPORTING_DATA: {
    code: 'ERROR_EXPORTING_DATA',
    message: 'Error al exportar datos',
  },
  ERROR_INVALID_ENTITY: {
    code: 'ERROR_INVALID_ENTITY',
    message: 'Entidad inválida',
  },
  ERROR_NO_DATA_TO_EXPORT: {
    code: 'ERROR_NO_DATA_TO_EXPORT',
    message: 'No hay datos para exportar',
  },
}

export const ErrorCodes = {
  //Export
  ...ExportErrors,
  //User
  ...UserErrors,
  //Tools
  ...ToolErrors,
  //Tools from warehouse
  ...ToolWarehouseErrors,
  //Raw materials
  ...RawMaterialErrors,
  //Warehouses
  ...WarehouseErrors,
  //Receipts
  ...ReceiptErrors,
  //Commits
  ...CommitErrors,
  //Blocks
  ...BlockErrors,
  //Clients
  ...ClientErrors,
  //Proformas
  ...ProformaErrors,
  //Events
  ...EventsErrors,
}

export class ErrorService extends Error {
  code: string
  message: string

  constructor(code: string, message: string) {
    super(message)
    this.code = code
    this.message = message
  }

  static get(error: (typeof ErrorCodes)[keyof typeof ErrorCodes]) {
    return new ErrorService(error.code, error.message)
  }

  static getFromError(error: Error) {
    if (error instanceof ErrorService) {
      return error
    }

    return new ErrorService(ErrorCodes.UNKNOWN.code, error.message)
  }
}
